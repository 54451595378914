import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { pdfdata } from "../const";
import imageUrl from "../assets/logo.jpg";
import EditIcon from "@mui/icons-material/Edit";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";
import { TEXT_MESSAGES } from "../const";
import arrow from "../assets/arow.png";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import { ADD_INVOICE_MUTATION } from "../graphql/mutation";
import SnackBar from "../Components/PheonixSnackBar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import share from "../assets/share.png";
import dayjs from "dayjs";
import { api } from "../api";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import QuoteData from "../interfaces/PheonixQuotation";
import QuoteTable from "../Components/QuoteTable";
import { RowData } from "../interfaces/PheonixProductTableprops";
import DLoadIcon from "../assets/DloadIcon.png";

const initialQuoteData: QuoteData = {
  "Expiry Date": "",
  "Quotation Date": "",
  "Quotation Number": "",
  "Customer Notes": "",
  "Total Amount": 0,
  "Discount Amount": 0,
  Email: "",
  "Customer Id": "",
  "Quotation Notes": "",
  "Terms and Conditions": "",
  "Customer No": "",
};
interface CustomerOption {
  id: string;
  name?: string;
  email: string;
  number?: string;
  gst_no?: string;
  address?: string;
  customerNo: string;
  city?: string;
  state?: string;
  pincode?: string;
  country?: string;
}
interface ProductOrService {
  name: string | null;
  tax: string | null;
  amount: Number | null;
  rate: Number | null;
  quantity: Number | null;
  productplan: string | null;
  billcycle: string | null;
  hsnCode: string | null;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  customerNo: "",
  city: "",
  state: "",
  pincode: "",
  country: "",
};
const NewQuotation: React.FC = () => {
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [quotationDate, setQuotationDate] = useState<Date | null>(null);
  const [status, setStatus] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [convert, setconvert] = useState(false);
  const [succMessage, setSuccMessage] = useState("");
  const [quotesData, setQuotesData] = useState<QuoteData>(initialQuoteData);
  const [fieldErrors, setFieldErrors] = useState<QuoteData>(initialQuoteData);
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const [error, setError] = useState("");
  const [newBreadcrumbText, setNewBreadcrumbText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const editQuote = location.state?.editQuote || null;
  const view = location.state?.view;
  const breadcrumbText = location.state?.breadcrumbText || "";
  const [rows, setRows] = useState<RowData[]>([
    {
      "Product Details": "",
      Quantity: 0,
      Rate: 0,
      Amount: 0,
      "Product Plan": "",
      "Billing Cycle": "",
      "HSN Number": "",
    },
  ]);
  type FieldErrors = {
    [index: string]: {
      [fieldName: string]: string;
    };
  };
  const [tableFieldErrors, setTableFieldErrors] = useState<FieldErrors>({});

  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState(true);
  const [rowDisable, setRowDisable] = useState(true);
  const [tableDisable, setTableDisable] = useState(false);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const areAllFieldsHasValues = (data: QuoteData) => {
    const fieldsToExclude = ["Quotation Number", "Discount Amount"];

    return Object.entries(data).every(([key, value]) => {
      return fieldsToExclude.includes(key) || value !== "";
    });
  };
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_CUSTOMERS,
        },
        config
      );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no: string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: {
                address_line1: string;
                city: string;
                state: string;
                pincode: string;
                country: string;
              };
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            customerNo: customer.customer_no,
            city: customer.basic_info.shipping_address.city,
            state: customer.basic_info.shipping_address.state,
            pincode: customer.basic_info.shipping_address.pincode,
            country: customer.basic_info.shipping_address.country,
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const checkForErrors = (): boolean => {
    for (const rowIndex in tableFieldErrors) {
      for (const field in tableFieldErrors[rowIndex]) {
        if (tableFieldErrors[rowIndex][field]) {
          return true;
        }
      }
    }
    return false;
  };

  const areAllRowsComplete = (rows: RowData[]): boolean => {
    return rows.every((row) => {
      return Object.entries(row).every(([key, value]) => {
        if (currency === "Dollars" || currency === "Europe") {
          if (key === "Tax") {
            return true;
          }
        }
        return value !== "";
      });
    });
  };
  const handleBack = () => {
    navigate("/quotation");
  };
  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setConfirmDisable(hasErrors);
  }, [fieldErrors]);

  useEffect(() => {
    const hasTableErrors = checkForErrors();
    setTableDisable(hasTableErrors);
  }, [tableFieldErrors]);

  useEffect(() => {
    const isButtonDisabled = areAllFieldsHasValues(quotesData);
    const isStatusValid = status !== "";
    const isCurrencyValid = currency !== "";

    if (isButtonDisabled && isStatusValid && isCurrencyValid) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [quotesData, status, currency]);

  useEffect(() => {
    const isAllRows = areAllRowsComplete(rows);
    if (isAllRows) {
      setRowDisable(false);
    } else {
      setRowDisable(true);
    }
  }, [rows]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const mapRowsToProductOrService = (rows: RowData[]): ProductOrService[] => {
    return rows.map((row) => ({
      name: row["Product Details"] || null,
      tax: row["Tax"] || null,
      amount: row["Amount"] ? Number(row["Amount"]) : null,
      rate: row["Rate"] ? Number(row["Rate"]) : null,
      quantity: row["Quantity"] ? Number(row["Quantity"]) : null,
      productplan: row["Product Plan"] || null,
      billcycle: row["Billing Cycle"] || null,
      hsnCode: row["HSN Number"] || null,
    }));
  };
  useEffect(() => {
    if (editQuote) {
      setQuotesData({
        "Customer Id": editQuote.customerId,
        Email: editQuote.email,
        "Quotation Number": editQuote.quotationid,
        "Total Amount": editQuote.total,
        "Customer No": editQuote.customerNo,
        "Customer Notes": editQuote.customernotes,
        "Expiry Date": editQuote.duedate,
        "Quotation Date": editQuote.quotationdate,
        "Quotation Notes": editQuote.quotationnotes,
        "Terms and Conditions": editQuote.terms_conditions,
        "Discount Amount": editQuote.discount,
      });
      const customer = customerOptions.find(
        (customer) => customer.id === editQuote.customerId.toString()
      );
      if (customer) {
        setQuotesData((prevQuotesData) => ({
          ...prevQuotesData,
          "Customer ID": customer.id,
          "customer No": customer.customerNo,
        }));
      }

      setRows(mapProductOrServiceToRows(editQuote.productOrService));
    }
    setCurrency(editQuote?.currency || "");
    setStatus(editQuote?.status || "");
  }, [editQuote]);

  const mapProductOrServiceToRows = (
    productOrService: ProductOrService[]
  ): RowData[] => {
    return productOrService.map((service) => ({
      "Product Details": service.name || "",
      Quantity: service.quantity || "",
      Rate: service.rate || "",
      Tax: service.tax || "",
      Amount: service.amount || "",
      "Product Plan": service.productplan || "",
      "Billing Cycle": service.billcycle || "",
      "HSN Number": service.hsnCode || "",
    }));
  };

  const customCloseIcon = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
      style={{ justifyContent: "end", width: "200px" }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const handleDownloadPDF = (rowData: QuoteData) => {
    const productOrService = mapRowsToProductOrService(rows);
    const doc = new jsPDF() as any;

    doc.addImage(imageUrl, "JPEG", 15, 10, 50, 35);

    doc.setFontSize(40);
    doc.setFont("helvetica", "bold");
    doc.text("Quotation", 130, 20);

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginRight = 15;
    const labelMarginLeft = 14;

    // Define rightAlignTextWithColors function
    const rightAlignTextWithColors = (
      label: any,
      value: any,
      labelColor: any,
      valueColor: any,
      yPosition: any,
      applyLabelMarginLeft: any
    ) => {
      const labelWidth =
        (doc.getStringUnitWidth(label) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const valueWidth =
        (doc.getStringUnitWidth(value) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const totalWidth = labelWidth + valueWidth;

      const xLabel =
        pageWidth -
        totalWidth -
        marginRight -
        (applyLabelMarginLeft ? labelMarginLeft : 0);
      const xValue =
        xLabel + labelWidth + (applyLabelMarginLeft ? labelMarginLeft : 0);

      doc.setTextColor(labelColor);
      doc.text(label, xLabel, yPosition);

      doc.setTextColor(valueColor);
      doc.text(value, xValue, yPosition);
    };

    // Prepare texts array
    const texts = [
      {
        label: "Quotation ID : ",
        value: rowData?.["Quotation Number"],
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Quotation Date : ",
        value: rowData?.["Quotation Date"],
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Valid Till : ",
        value: rowData?.["Expiry Date"],
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
    ];

    const yPositions = [28, 35, 42];
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    // Apply rightAlignTextWithColors to each text
    texts.forEach((text, index) => {
      rightAlignTextWithColors(
        text.label,
        text.value,
        text.labelColor,
        text.valueColor,
        yPositions[index],
        text.applyLabelMarginLeft
      );
    });

    doc.setTextColor("#808080");
    doc.text("From ", 15, 60);
    doc.setTextColor("#000000");

    const pheonixAddress = pdfdata.PHEONIX_ADDRESS;
    doc.text(pheonixAddress, 12, 65);

    const text1 = "To ";
    const customerDetails = customerOptions.find(
      (customer) => customer.id === rowData["Customer Id"]
    );

    const text2 = customerDetails?.name
      ? customerDetails.name
      : customerDetails?.customerNo ?? "";
    const text3 = customerDetails?.number ?? "";

    const textWidth1 =
      (doc.getStringUnitWidth(text1) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textWidth2 =
      (doc.getStringUnitWidth(text2) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textWidth3 =
      (doc.getStringUnitWidth(text3) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const x1 = pageWidth - textWidth1 - marginRight;
    const x2 = pageWidth - textWidth2 - marginRight;
    const x3 = pageWidth - textWidth3 - marginRight;

    doc.setTextColor("#808080");
    doc.text(text1, x1, 60);
    doc.setTextColor("#000000");
    doc.text(text2, x2, 70);
    doc.text(text3, x3, 75);

    const products = productOrService.map((service: any) => ({
      name: service.name,
      amount: service.amount,
      productplan: service.productplan,
      tax: service.tax,
    }));
    const subtotal = products.reduce(
      (sum, product) => sum + parseFloat(product.amount),
      0
    );
    let cgst = 0;
    let sgst = 0;
    let igst = 0;

    const currencydata = currency;
    const gstNumber = customerDetails?.gst_no || "";

    if (currencydata === "Rupees") {
      if (!gstNumber) {
        products.forEach((product) => {
          const productAmount = parseFloat(product.amount);
          cgst += productAmount * 0.09;
          sgst += productAmount * 0.09;
        });
      } else if (gstNumber.startsWith("33")) {
        products.forEach((product) => {
          const productAmount = parseFloat(product.amount);
          cgst += productAmount * 0.09;
          sgst += productAmount * 0.09;
        });
      } else {
        products.forEach((product) => {
          const productAmount = parseFloat(product.amount);
          igst += productAmount * 0.18;
        });
      }
    }

    const bodyData = products.map((name) => [
      name.name,
      name.productplan,
      name.amount,
    ]);
    let startY = 110;
    const customStyles = {
      fillColor: [255, 255, 255],
      lineHeight: 50,
      cellPadding: 2,
      textColor: [0, 0, 0],
      fontSize: 12,
    };
    doc.autoTable({
      startY,
      head: [["Product/Service", "Product Plan", "Price"]],
      body: bodyData,
      styles: customStyles,
      didParseCell: function (table: any) {
        if (table.section === "head") {
          table.cell.styles.textColor = "#000000";
          table.cell.styles.fontSize = 12;
        }
      },
    });

    if (currencydata === "Rupees") {
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);

      const subtotalText = `Subtotal: ${subtotal.toFixed(2)}`;
      doc.text(subtotalText, 150, 160);

      if (cgst > 0 || sgst > 0) {
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        doc.text(`CGST 9%: ${cgst.toFixed(2)}`, 150, 170);
        doc.text(`SGST 9%: ${sgst.toFixed(2)}`, 150, 180);
      } else if (igst > 0) {
        const gstText = `IGST 18%: ${igst.toFixed(2)}`;
        doc.text(gstText, 150, 170);
      }
    }

    const totalText1 = `Total: ${rowData?.["Total Amount"] || "N/A"}`;
    if (currencydata !== "Rupees") {
      doc.text(totalText1, 150, 160);
    } else {
      doc.text(totalText1, 150, 190);
    }
    doc.save(`${rowData["Quotation Number"]}.pdf`);
  };

  const handleConvertToInvoice = async () => {
    const productOrService = mapRowsToProductOrService(rows);
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const query = ADD_INVOICE_MUTATION;
    const variables = {
      user_id: userId,
      invoicedate: quotesData["Quotation Date"],
      duedate: quotesData["Expiry Date"],
      expiry_date: quotesData["Expiry Date"],
      productOrService: productOrService,
      price: quotesData["Total Amount"],
      paid_amount: "0",
      status: "Unpaid",
      customer_name: quotesData["Customer Name"],
      customer_id: quotesData["Customer Id"],
      email: quotesData["Email"],
      description: quotesData["Customer Notes"],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { addInvoice } = response.data.data;
      if (addInvoice && addInvoice.id) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_SUCCESS);
        setconvert(true);
      } else {
        setSuccMessage(TEXT_MESSAGES.Invoice_FAILURE);
      }
      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/invoice");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation AddQuotation($user_id: ID!
      $name: String
      $quotationdate: String
      $productOrService: [ProductOrServiceInput]
      $customernotes: String
      $quotationnotes: String
      $quotationid: String
      $currency: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber:Int
      $email: String
      $address: String
      $duedate: String!
      $price: String!
      $discount: Int
      $taxamount: Int
      $terms_conditions: String
      $status: String! ){
      addQuotation(user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        quotationid: $quotationid
        productOrService: $productOrService
        customernotes: $customernotes
        quotationnotes: $quotationnotes
        currency: $currency
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        terms_conditions: $terms_conditions
        price: $price
        discount: $discount
        taxamount: $taxamount
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          customernotes
          quotationnotes
          currency
          customerName
          customerNo
          customerId
          phoneNumber
          email
          address
          terms_conditions
          price
          status
      }
    }
    `;

    const variables = {
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      quotationid: quotesData["Quotation Number"],
      productOrService: productOrService,
      customernotes: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      customerNo: quotesData["Customer No"],
      email: quotesData["Email"] ? quotesData["Email"] : "",
      duedate: quotesData["Expiry Date"],
      price: quotesData["Total Amount"]?.toString(),
      status: status,
      currency: currency,
      terms_conditions: quotesData["Terms and Conditions"],
      discount: quotesData["Discount Amount"],
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { addQuotation } = response.data.data;

      if (addQuotation && addQuotation.id) {
        setSuccMessage(TEXT_MESSAGES.QUOTATION_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_FAILURE);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotation");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDraft = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const productOrService = mapRowsToProductOrService(rows);
    if (editQuote && edit) {
      const query = `
      mutation EditQuotation(
        $_id: ID!
        $user_id: ID!
        $name: String
        $quotationdate: String!
        $quotationid: String
        $productOrService: [ProductOrServiceInput!]!
        $customernotes: String
        $quotationnotes: String
        $currency: String
        $customerName: String
        $customerId:String!
        $customerNo: String
        $phoneNumber:Int
        $email: String!
        $address: String
        $duedate: String!
        $price: String!
        $discount: Int
        $taxamount: Int
        $terms_conditions: String
        $status: String! ){
          editQuotation(
          _id: $_id
          user_id: $user_id
          name: $name
          quotationdate: $quotationdate
          quotationid: $quotationid
          productOrService: $productOrService
          customernotes: $customernotes
          quotationnotes: $quotationnotes
          currency: $currency
          customerName: $customerName
          customerNo: $customerNo
          customerId: $customerId
          phoneNumber:$phoneNumber
          email:$email
          address:$address
          duedate: $duedate
          terms_conditions: $terms_conditions
          price: $price
          discount: $discount
          taxamount: $taxamount
          status: $status){
            id
            serial_no
            user_id
            name
            quotationid
            quotationdate
            productOrService {
          name
          tax
          amount
          rate
          quantity
        }
            duedate
            customernotes
            quotationnotes
            currency
            customerName
            customerNo
            customerId
            phoneNumber
            email
            address
            terms_conditions
            price
            status
        }
      }
      `;

      const variables = {
        _id: editQuote.id,
        user_id: userId,
        name: quotesData["Customer Name"],
        quotationdate: quotesData["Quotation Date"],
        quotationid: quotesData["Quotation Number"],
        productOrService: productOrService,
        description: quotesData["Customer Notes"],
        quotationnotes: quotesData["Quotation Notes"],
        terms_conditions: quotesData["Terms and Conditions"],
        customerName: quotesData["Customer Name"],
        customerId: quotesData["Customer Id"],
        customerNo: quotesData["Customer No"],
        email: quotesData["Email"],
        duedate: quotesData["Expiry Date"],
        price: quotesData["Total Amount"]?.toString(),
        status: "Draft",
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query,
            variables,
          },
          config
        );
        const { editQuotation } = response.data.data;

        if (editQuotation && editQuotation.id) {
          setSuccMessage(TEXT_MESSAGES.QUOTE_DRAFT_SUCCESS);
        } else {
          setSuccMessage(TEXT_MESSAGES.QUOTE_DRAFT_FAIL);
        }

        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
          navigate("/quotation");
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    } else {
      const query = `
    mutation AddQuotation($user_id: ID!
      $name: String
      $quotationdate: String
      $productOrService: [ProductOrServiceInput]
      $customernotes: String
      $quotationnotes: String
      $quotationid: String
      $currency: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber:Int
      $email: String
      $address: String
      $duedate: String!
      $price: String!
      $discount: Int
      $taxamount: Int
      $terms_conditions: String
      $status: String! ){
      addQuotation(user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        quotationid: $quotationid
        productOrService: $productOrService
        customernotes: $customernotes
        quotationnotes: $quotationnotes
        currency: $currency
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        terms_conditions: $terms_conditions
        price: $price
        discount: $discount
        taxamount: $taxamount
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          customernotes
          quotationnotes
          currency
          customerName
          customerNo
          customerId
          phoneNumber
          email
          address
          terms_conditions
          price
          status
      }
    }
    `;

      const variables = {
        user_id: userId,
        name: quotesData["Customer Name"],
        quotationdate: quotesData["Quotation Date"],
        quotationid: quotesData["Quotation Number"],
        productOrService: productOrService,
        customernotes: quotesData["Customer Notes"],
        quotationnotes: quotesData["Quotation Notes"],
        customerName: quotesData["Customer Name"],
        customerId: quotesData["Customer Id"],
        customerNo: quotesData["Customer No"],
        email: quotesData["Email"] ? quotesData["Email"] : "",
        duedate: quotesData["Expiry Date"],
        price: quotesData["Total Amount"]?.toString(),
        status: "Draft",
        currency: currency,
        terms_conditions: quotesData["Terms and Conditions"],
        discount: quotesData["Discount Amount"],
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query,
            variables,
          },
          config
        );
        const { addQuotation } = response.data.data;

        if (addQuotation && addQuotation.id) {
          setSuccMessage(TEXT_MESSAGES.QUOTE_DRAFT_SUCCESS);
        } else {
          setSuccMessage(TEXT_MESSAGES.QUOTE_DRAFT_FAIL);
        }

        setModalOpen(true);
        setTimeout(() => {
          setModalOpen(false);
          navigate("/quotation");
        }, 3000);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleEditClick = () => {
    setNewBreadcrumbText("Edit Quotation");
    setEdit(true);
  };
  const handleShareEmail = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation ResendEmail($user_id: ID!
      $name: String
     $quotationid: String 
      $quotationdate: String
      $productOrService: [ProductOrServiceInput]
      $customernotes: String
      $quotationnotes: String
      $currency: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber:Int
      $email: String
      $address: String
      $duedate: String!
      $price: String!
      $discount: Int
      $taxamount: Int
      $terms_conditions: String
      $status: String! ){
      resendEmail(user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        quotationid: $quotationid
        productOrService: $productOrService
        customernotes: $customernotes
        quotationnotes: $quotationnotes
        currency: $currency
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        terms_conditions: $terms_conditions
        price: $price
        discount: $discount
        taxamount: $taxamount
        status: $status){
         success
         message
      }
    }
    `;

    const variables = {
      _id: editQuote.id,
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      quotationid: quotesData["Quotation Number"],
      productOrService: productOrService,
      description: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      terms_conditions: quotesData["Terms and Conditions"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      customerNo: quotesData["Customer No"],
      email: quotesData["Email"],
      duedate: quotesData["Expiry Date"],
      price:
        typeof quotesData["Total Amount"] === "string"
          ? quotesData["Total Amount"]
          : String(quotesData["Total Amount"]),
      status: status,
      currency: currency,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { resendEmail } = response.data.data;

      if (resendEmail && resendEmail.success) {
        setSuccMessage(TEXT_MESSAGES.QUOTE_SHARE_SUCCESS);
      } else {
        setSuccMessage(TEXT_MESSAGES.QUOTE_SHARE_FAILED);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const productOrService = mapRowsToProductOrService(rows);
    const query = `
    mutation EditQuotation(
      $_id: ID!
      $user_id: ID!
      $name: String
      $quotationdate: String!
      $quotationid: String
      $productOrService: [ProductOrServiceInput!]!
      $customernotes: String
      $quotationnotes: String
      $currency: String
      $customerName: String
      $customerId:String!
      $customerNo: String
      $phoneNumber:Int
      $email: String!
      $address: String
      $duedate: String!
      $price: String!
      $discount: Int
      $taxamount: Int
      $terms_conditions: String
      $status: String! ){
        editQuotation(
        _id: $_id
        user_id: $user_id
        name: $name
        quotationdate: $quotationdate
        quotationid: $quotationid
        productOrService: $productOrService
        customernotes: $customernotes
        quotationnotes: $quotationnotes
        currency: $currency
        customerName: $customerName
        customerNo: $customerNo
        customerId: $customerId
        phoneNumber:$phoneNumber
        email:$email
        address:$address
        duedate: $duedate
        terms_conditions: $terms_conditions
        price: $price
        discount: $discount
        taxamount: $taxamount
        status: $status){
          id
          serial_no
          user_id
          name
          quotationid
          quotationdate
          productOrService {
        name
        tax
        amount
        rate
        quantity
      }
          duedate
          customernotes
          quotationnotes
          currency
          customerName
          customerNo
          customerId
          phoneNumber
          email
          address
          terms_conditions
          price
          status
      }
    }
    `;

    const variables = {
      _id: editQuote.id,
      user_id: userId,
      name: quotesData["Customer Name"],
      quotationdate: quotesData["Quotation Date"],
      quotationid: quotesData["Quotation Number"],
      productOrService: productOrService,
      description: quotesData["Customer Notes"],
      quotationnotes: quotesData["Quotation Notes"],
      terms_conditions: quotesData["Terms and Conditions"],
      customerName: quotesData["Customer Name"],
      customerId: quotesData["Customer Id"],
      customerNo: quotesData["Customer No"],
      email: quotesData["Email"],
      duedate: quotesData["Expiry Date"],
      price: quotesData["Total Amount"]?.toString(),
      status: status,
      currency: currency,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { editQuotation } = response.data.data;

      if (editQuotation && editQuotation.id) {
        setSuccMessage(TEXT_MESSAGES.QUOTATION_UPDATE);
      } else {
        setSuccMessage(TEXT_MESSAGES.Quotation_UPDATE_FAILURE);
      }

      setModalOpen(true);
      setTimeout(() => {
        setModalOpen(false);
        navigate("/quotation");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setQuotesData({
      "Expiry Date": "",
      "Quotation Date": "",
      "Quotation Number": "",
      "Customer Notes": "",
      "Total Amount": 0,
      "Discount Amount": 0,
      "Customer Name": "",
      "Customer No": "",
      Email: "",
      "Quotation Notes": "",
      "Terms and Conditions": "",
    });
    navigate("/quotation");
  };

  return (
    <Box
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "1%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginLeft: "1%",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.QUOTATION}
          </Typography>
          <PheonixBreadcrumbs
            breadcrumbText={newBreadcrumbText || breadcrumbText}
          />
        </div>

        <div style={{ display: "flex", height: "35px" }}>
          <Button
            onClick={handleBack}
            sx={{
              color: "#181D8C",
              border: "1px solid",
              backgroundColor: "white",
              width: "208px",
              borderRadius: "5px",
              marginRight: "20px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            <img
              src={arrow}
              style={{
                marginRight: "10px",
                marginLeft: 0,
                height: "16px",
                width: "18px",
              }}
            />
            {TEXT_MESSAGES.BACK_QUOTE}
          </Button>
        </div>
      </Box>
      <div
        style={{
          display: "flex",

          flexDirection: "column",
          marginTop: "15px",
          border: "0.5px solid #B7BAFF",
          padding: "5px",
          width: "97%",
          marginLeft: "1%",
          borderRadius: "4px",
          height: "70vh",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1%",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "5px",
            padding: "5px",
            marginTop: "0px",
            marginLeft: "-5px",
            marginRight: "0%",
            width: "95%",
          }}
        >
          {editQuote?.status === "Accepted" ? (
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                marginTop: "1%",
              }}
            >
              {!convert && (
                <Button
                  onClick={handleConvertToInvoice}
                  sx={{
                    color: "white",
                    border: "1px solid",
                    backgroundColor: "#181D8C",
                    height: "35px",
                    borderRadius: "5px",

                    textTransform: "none",
                    "&:hover": { backgroundColor: "#181D8C" },
                    "&:active": { backgroundColor: "#181D8C" },
                  }}
                >
                  {TEXT_MESSAGES.CONVERT_TXT}
                </Button>
              )}
              <Button
                onClick={() => handleDownloadPDF(quotesData)}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                Download
              </Button>
              <Button
                onClick={handleShareEmail}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                Share in Email
              </Button>
              <Button
                onClick={handleEditClick}
                disabled={edit}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                <EditIcon
                  sx={{
                    marginRight: "5px",
                    marginLeft: 0,
                    height: "16px",
                    width: "18px",
                  }}
                />
                {TEXT_MESSAGES.EDIT}
              </Button>
            </Box>
          ) : editQuote ? (
            <Box
              sx={{
                display: "flex",
                gap: "1%",
                alignContent: "center",
                justifyContent: "flex-end",
                flexDirection: "row",
                marginTop: "1%",
              }}
            >
              <Button
                onClick={() => handleDownloadPDF(quotesData)}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                <img
                  src={DLoadIcon}
                  alt="Download Icon"
                  style={{
                    marginRight: "5px",
                    marginLeft: 0,
                    height: "18px",
                    width: "14px",
                  }}
                />
                Download
              </Button>
              <Button
                onClick={handleShareEmail}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                <AttachEmailOutlinedIcon
                  sx={{
                    marginRight: "5px",
                    marginLeft: 0,
                    height: "16px",
                    width: "18px",
                  }}
                />
                Share in Email
              </Button>
              <Button
                onClick={handleEditClick}
                disabled={edit}
                sx={{
                  color: "white",
                  border: "1px solid",
                  backgroundColor: edit ? "lightgrey" : "#181D8C",
                  height: "35px",
                  borderRadius: "5px",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#181D8C" },
                  "&:active": { backgroundColor: "#181D8C" },
                }}
              >
                <EditIcon
                  sx={{
                    marginRight: "5px",
                    marginLeft: 0,
                    height: "16px",
                    width: "18px",
                  }}
                />
                {TEXT_MESSAGES.EDIT}
              </Button>
            </Box>
          ) : (
            ""
          )}
          <QuoteTable
            QuotesData={quotesData}
            setQuotesData={setQuotesData}
            status={status}
            setStatus={setStatus}
            currency={currency}
            setCurrency={setCurrency}
            QuotationDate={quotationDate}
            setQuotationDate={setQuotationDate}
            ExpiryDate={expiryDate}
            setExpiryDate={setExpiryDate}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            customerOptions={customerOptions}
            setCustomerOptions={setCustomerOptions}
            rows={rows}
            setRows={setRows}
            tableFieldErrors={tableFieldErrors}
            setTableFieldErrors={setTableFieldErrors}
            view={view}
            edit={edit}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              alignSelf: "flex-end",
              marginTop: "10px",
              flexDirection: "column",
              marginLeft: "10%",
            }}
          >
            <div
              style={{
                height: "0.5px",
                width: "110%",
                backgroundColor: "#CBC2E9",
              }}
            ></div>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Button
                onClick={handleCancel}
                style={{
                  color: "#181d8C",
                  backgroundColor: "white",
                  border: "1px solid #181D8C",
                  width: "125px",
                  height: "36px",
                  borderRadius: "5px",
                  marginLeft: "2%",
                }}
              >
                {TEXT_MESSAGES.CANCEL}
              </Button>

              {edit ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignSelf: "flex-end",
                  }}
                >
                  <Button
                    onClick={handleDraft}
                    style={{
                      color: "white",
                      backgroundColor: "#181d8C",
                      border: "1px solid",
                      width: "125px",
                      height: "36px",
                      borderRadius: "5px",
                      fontSize: "14px",
                      textTransform: "none",
                      marginRight: "2px",
                    }}
                  >
                    Save as draft
                  </Button>
                  <Button
                    onClick={handleEdit}
                    disabled={buttonDisable || confirmDisable}
                    sx={{
                      color: "white",
                      border: "1px solid",
                      backgroundColor:
                        buttonDisable ||
                        confirmDisable ||
                        rowDisable ||
                        tableDisable
                          ? "lightgray"
                          : "#181D8C",
                      width: "125px",
                      height: "36px",
                      borderRadius: "5px",
                      marginRight: "2%",
                      "&:hover": { backgroundColor: "#181D8C" },
                      "&:active": { backgroundColor: "#181D8C" },
                    }}
                  >
                    {TEXT_MESSAGES.UPDATE_TXT}
                  </Button>
                </div>
              ) : (
                view === undefined && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleDraft}
                      style={{
                        color: "white",
                        backgroundColor: "#181d8C",
                        border: "1px solid",
                        width: "125px",
                        height: "36px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        textTransform: "none",
                        marginRight: "2px",
                      }}
                    >
                      Save as draft
                    </Button>
                    <Button
                      onClick={handleSave}
                      disabled={buttonDisable || confirmDisable}
                      sx={{
                        color: "white",
                        border: "1px solid",
                        backgroundColor:
                          buttonDisable ||
                          confirmDisable ||
                          rowDisable ||
                          tableDisable
                            ? "lightgray"
                            : "#181D8C",
                        width: "125px",
                        height: "36px",
                        borderRadius: "5px",
                        marginRight: "2%",
                        "&:hover": { backgroundColor: "#181D8C" },
                        "&:active": { backgroundColor: "#181D8C" },
                      }}
                    >
                      {TEXT_MESSAGES.SAVE}
                    </Button>
                  </div>
                )
              )}
            </Box>
          </div>
        </Box>
      </div>

      <SnackBar
        open={modalOpen}
        onClose={handleClose}
        message={succMessage}
        vertical="top"
        horizontal="center"
        customAction={customCloseIcon}
      />
    </Box>
  );
};
export default NewQuotation;
