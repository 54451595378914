import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import Flatpickr from "react-flatpickr";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { api } from "../api";
import axios from "axios";
import { GET_ALL_PRODUCTS } from "../graphql/query";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixProductTable from "./PheonixProductTable";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RowData, TableColumn } from "../interfaces/PheonixProductTableprops";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import QuoteTableProps from "../interfaces/QuoteTableProps";
import QuoteData from "../interfaces/PheonixQuotation";
import { ProductData } from "../interfaces/PheonixProductTableprops";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const QuoteTable: React.FC<QuoteTableProps> = ({
  QuotesData,
  view,
  edit,
  setQuotesData,
  status,
  setStatus,
  currency,
  setCurrency,
  QuotationDate,
  setQuotationDate,
  ExpiryDate,
  setExpiryDate,
  fieldErrors,
  setFieldErrors,
  customerOptions,
  setCustomerOptions,
  rows,
  setRows,
  tableFieldErrors,
  setTableFieldErrors,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [allproductsData, setAllProductsData] = useState<ProductData[]>([]);
  const [error, setError] = useState("");

  const calculateTotalAmount = (
    rows: any[]
  ): { totalAmount: number; totalTax: number } => {
    let totalAmount = 0;
    let totalTax = 0;

    rows.forEach((row) => {
      let taxRate = 0;
      if (row.Tax === "GST 18%") {
        taxRate = 18;
      } else if (row.Tax === "Non-Taxable") {
        taxRate = 0;
      }

      const amount = parseFloat(row.Amount || 0);
      const taxAmount = (amount * taxRate) / 100;

      totalAmount += amount;
      totalTax += taxAmount;
    });
    const roundTotal = totalAmount + totalTax;

    return {
      totalAmount: Math.round(roundTotal * 100) / 100,
      totalTax: Math.round(totalTax * 100) / 100,
    };
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if ((view || edit) && customerOptions.length > 0) {
      setSelectedCustomer(
        customerOptions.find(
          (customer) => customer.customerNo === QuotesData["Customer No"]
        )
      );
    }
  }, [view, edit, customerOptions, QuotesData]);
  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_PRODUCTS,
        },
        config
      );
      const products = response.data.data.getallproducts;
      const formattedProducts = products.map((product: any) => ({
        "Product Name": product.name || "",
        "Product Plan": product.productplan || "",
        "Billing Cycle": product.billcycle || "",
        Tax: product.tax || 0,
        Rate: product.rate || 0,
        "HSN Number": product.hsnCode || "",
      }));
      setAllProductsData(formattedProducts);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  useEffect(() => {
    const { totalAmount, totalTax } = calculateTotalAmount(rows);
    setTotalAmount(totalAmount);
    setTotalTax(totalTax / 2);
    setQuotesData((prevState) => ({
      ...prevState,
      ["Total Amount"]: totalAmount,
    }));
  }, [rows]);

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };
  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  };

  const handleQuotationDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Quotation Date"]: formattedDate,
    }));
  };
  const handleExpiryDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Expiry Date"]: formattedDate,
    }));
  };

  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSelectedCustomer(newValue);
    const { id, email, customerNo } = newValue;
    setQuotesData((prevQuoteData) => ({
      ...prevQuoteData,
      "Customer No": customerNo,
      "Customer Id": id,
      Email: email,
    }));
  };

  const handleInputChange = (label: string, value: string) => {
    const newFormData: QuoteData = { ...QuotesData, [label]: value };
    switch (label) {
      case "GST Number":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERROR,
          }));
        } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_ERR1,
          }));
        } else if (value.length !== 15) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.GST_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;

      case "Address":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.ADD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Quotation Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Customer Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Terms and Conditions":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Product/Plan":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.PROD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Phone Number":
        if (!/^[0-9]*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (value.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Total Amount":
        break;
      case "Discount Amount":
        break;
      case "Quotation Number":
        break;
      case "Tax Amount":
        if (!/^₹?\d*\.?\d*$/.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      case "Email":
        if (!TEXT_MESSAGES.EMAIL_REG.test(value)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;
      default:
        return true;
    }

    setQuotesData(newFormData);
  };

  const handleDeleteRow = (index: number) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
      setTableFieldErrors((prevErrors: { [key: string]: any }) => {
        const newErrors = { ...prevErrors };
        if (newErrors[index]) {
          Object.keys(newErrors[index]).forEach((field) => {
            newErrors[index][field] = "";
          });
        }
        return newErrors;
      });
    }
  };
  const handleAddRow = () => {
    const newRow = {
      "Product Details": "",
      Quantity: 0,
      Rate: 0,
      Tax: "",
      Amount: 0,
      "Product Plan": "",
      "Billing Cycle": "",
      "HSN Number": "",
    };
    setRows([...rows, newRow]);
  };
  const getCurrencySymbol = (currency: any) => {
    switch (currency) {
      case "Rupees":
        return "₹";
      case "Dollars":
        return "$";
      case "Europe":
        return "€";
      default:
        return "";
    }
  };

  const handleInputChange1 = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: any
  ) => {
    const newRows = [...rows];

    let hasError = false;

    if (fieldName === "Product Details") {
      const selectedProduct = allproductsData.find(
        (product) => product["Product Name"] === value
      );

      if (selectedProduct) {
        newRows[index] = {
          ...newRows[index],
          "Product Details": value,
          "HSN Number": selectedProduct["HSN Number"],
          Tax: selectedProduct.Tax,
          Rate: selectedProduct.Rate,
          "Product Plan": selectedProduct["Product Plan"],
          Amount: (
            selectedProduct.Rate * (newRows[index].Quantity || 0)
          ).toFixed(2),
        };
      } else {
        newRows[index] = {
          ...newRows[index],
          "Product Details": value,
          "HSN Number": "",
          Tax: "",
          Rate: 0,
          "Product Plan": "",
          Amount: 0,
        };
      }
    } else {
      newRows[index] = {
        ...newRows[index],
        [fieldName]: value,
      };

      if (fieldName === "Rate" || fieldName === "Quantity") {
        const rate = Number(newRows[index].Rate) || 0;
        const quantity = Number(newRows[index].Quantity) || 0;
        const amount = (rate * quantity).toFixed(2);
        newRows[index].Amount = amount;
      }
    }

    if (fieldName === "Product Details" && /[^\w\s]/.test(value)) {
      hasError = true;
      setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          [fieldName]: ERROR_MESSAGES.BATCH_VAL,
        },
      }));
    } else {
      setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          [fieldName]: "",
        },
      }));
    }

    if (fieldName === "Rate") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.MRP_VAL,
          },
        }));
      }
    } else if (fieldName === "Quantity") {
      if (!value) {
        newRows[index].Quantity = 0;
        newRows[index].Amount = (Number(newRows[index].Rate) * 0).toFixed(2);
      } else if (!/^\d+$/.test(value)) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_VAL,
          },
        }));
      } else {
        newRows[index].Quantity = Number(value);
      }
    } else if (fieldName === "Amount") {
      const isValidNumber = /^\d*.?\d+$/.test(value) && parseFloat(value) >= 0;
      if (!isValidNumber) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_VAL,
          },
        }));
      } else if (!value) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.AMT_REQ,
          },
        }));
      }
    } else if (fieldName === "HSN Number") {
      if (!/^[0-9]*$/.test(value)) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_VAL,
          },
        }));
      } else if (value.length > 8) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_ERROR,
          },
        }));
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_ERR1,
          },
        }));
      } else if (!value) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.HSN_REQ,
          },
        }));
      }
    }

    if (!hasError) {
      setRows(newRows);
    }
  };
  const calculateFinalAmount = () => {
    let discountPercentage = 0;

    if (QuotesData["Discount Amount"] !== undefined) {
      discountPercentage = QuotesData["Discount Amount"];
    }
    const { totalAmount, totalTax } = calculateTotalAmount(rows);
    setTotalAmount(totalAmount + totalTax);
    setTotalTax(totalTax / 2);
    const discountAmount = (discountPercentage / 100) * totalAmount;
    const finalAmount = parseFloat((totalAmount - discountAmount).toFixed(2));
    setTotalAmount(finalAmount);
    if (QuotesData["Total Amount"] !== finalAmount) {
      setQuotesData((prevData) => ({
        ...prevData,
        "Total Amount": finalAmount,
      }));
    }
  };

  useEffect(() => {
    calculateFinalAmount();
  }, [QuotesData["Discount Amount"], rows]);

  const discountOptions = [1, 2, 3, 4, 5];
  const handleDiscountChange = (
    event: React.ChangeEvent<{}>,
    newValue: any
  ) => {
    setQuotesData((prevData) => ({
      ...prevData,
      "Discount Amount": newValue,
    }));
    const discountPercentage = parseFloat(newValue) || 0;
    const discountAmount = (discountPercentage / 100) * totalAmount;
    const finalAmount = parseFloat((totalAmount - discountAmount).toFixed(2));
    setTotalAmount(finalAmount);
    setQuotesData((prevState) => ({
      ...prevState,
      ["Total Amount"]: finalAmount,
    }));
  };
  const getColumns = () => {
    const baseColumns = [
      {
        field: "Product Details",
        headerName: "Product Details",
      },
      {
        field: "Product Plan",
        headerName: "Product Plan",
      },
      {
        field: "Billing Cycle",
        headerName: "Billing Cycle",
      },
      {
        field: "HSN Number",
        headerName: "HSN Number",
      },
      {
        field: "Quantity",
        headerName: "Quantity",
      },
      {
        field: "Rate",
        headerName: "Rate",
      },
      {
        field: "Amount",
        headerName: "Amount",
      },
      {
        field: "Action",
        headerName: "Action",
        render: (index: number) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              disabled={view && !edit}
              onClick={() => handleAddRow()}
              style={{ color: "#181D8C" }}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              disabled={view && !edit}
              onClick={() => handleDeleteRow(index)}
              style={{ color: "#D32F2F" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    if (currency !== "Dollars" && currency !== "Europe") {
      baseColumns.splice(6, 0, {
        field: "Tax",
        headerName: "Tax",
      });
    }

    return baseColumns;
  };

  const columns = getColumns();
  const isIntraState = (gstNumber: any) => {
    const companyStateCode = "33";
    const customerStateCode = gstNumber.slice(0, 2);
    return customerStateCode === companyStateCode;
  };

  return (
    <Box>
      <table style={{ width: "100%", marginTop: "1%" }}>
        <tbody>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                disabled={view && !edit}
                value={QuotesData["Quotation Number"]}
                placeholder="Quotation Number"
                label={
                  <span style={{ color: "#181D8C" }}>Quotation Number</span>
                }
                name="Quotation Number"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Quotation Number"]}
                helperText={fieldErrors["Quotation Number"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "309px", marginRight: "30px" }}
              />
              <Autocomplete
                disablePortal
                options={customerOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <>
                        <span style={{ color: "#181D8C" }}>
                          Select Customer
                        </span>
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </>
                    }
                    disabled={view && !edit}
                  />
                )}
                disableClearable={true}
                disabled={view && !edit}
                getOptionLabel={(option) =>
                  option.name
                    ? `${option.name} (${option.customerNo})`
                    : option.customerNo
                }
                onChange={(event, newValue) =>
                  handleNameChange(event, newValue)
                }
                value={
                  customerOptions.find(
                    (customer) =>
                      customer.customerNo === QuotesData["Customer No"]
                  ) || { id: "", customerNo: "", email: "" }
                }
                style={{ width: "309px" }}
              />
              <div style={{ marginLeft: "30px" }}>
                <FormControl sx={{ width: "309px" }}>
                  <InputLabel id="demo-select-small-label">
                    <span style={{ color: "#181D8C" }}>Select Currency</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={currency}
                    label="Select Currency"
                    onChange={handleCurrencyChange}
                    disabled={view && !edit}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Rupees"}>Rupees</MenuItem>
                    <MenuItem value={"Dollars"}>Dollars</MenuItem>
                    <MenuItem value={"Europe"}>Euro</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    <>
                      <span style={{ color: "#181D8C" }}>Quotation Date</span>
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </>
                  }
                  format="DD/MM/YYYY"
                  disabled={view && !edit}
                  onChange={handleQuotationDateChange}
                  disablePast
                  value={
                    QuotesData["Quotation Date"]
                      ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                      : null
                  }
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
              <div style={{ marginLeft: "30px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={
                      <>
                        <span style={{ color: "#181D8C" }}>Expiry Date</span>
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </>
                    }
                    format="DD/MM/YYYY"
                    disabled={view && !edit}
                    onChange={handleExpiryDateChange}
                    disablePast
                    minDate={
                      QuotesData["Quotation Date"]
                        ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                        : dayjs()
                    }
                    value={
                      QuotesData["Expiry Date"]
                        ? dayjs(QuotesData["Expiry Date"], "DD/MM/YYYY")
                        : null
                    }
                    sx={{
                      width: 309,
                    }}
                  />
                </LocalizationProvider>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>

          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <PheonixTextField
                id="outlined-error"
                type="text"
                disabled={view && !edit}
                value={QuotesData["Quotation Notes"]}
                placeholder="Quotation Notes"
                label={
                  <>
                    <span style={{ color: "#181D8C" }}>Quotation Notes</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </>
                }
                error={!!fieldErrors?.["Quotation Notes"]}
                helperText={fieldErrors["Quotation Notes"]}
                name="Quotation Notes"
                variant="outlined"
                size="small"
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
              />
            </td>
            <td style={{ width: "25%" }}> </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          {selectedCustomer && (
            <tr>
              <td
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 2fr)",
                  gap: "10px",
                  padding: "10px",
                  fontFamily: "Inter",
                  color: "#181D8C",
                  fontSize: "16px",
                }}
              >
                {selectedCustomer.number && (
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body1">
                      <strong>Phone Number:</strong> {selectedCustomer.number}
                    </Typography>
                  </div>
                )}
                {selectedCustomer.email && (
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body1">
                      <strong>Email:</strong> {selectedCustomer.email}
                    </Typography>
                  </div>
                )}
                {selectedCustomer.gst_no && (
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body1">
                      <strong>GST No:</strong> {selectedCustomer.gst_no}
                    </Typography>
                  </div>
                )}

                {selectedCustomer.address && (
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body1">
                      <strong>Address:</strong> {selectedCustomer.address}
                    </Typography>
                  </div>
                )}
                {selectedCustomer.city && (
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body1">
                      <strong>City:</strong> {selectedCustomer.city}
                    </Typography>
                  </div>
                )}
                {selectedCustomer.pincode && (
                  <div style={{ marginBottom: "10px" }}>
                    <Typography variant="body1">
                      <strong>PinCode:</strong> {selectedCustomer.pincode}
                    </Typography>
                  </div>
                )}
                {selectedCustomer.state && (
                  <div>
                    <Typography variant="body1">
                      <strong>State:</strong> {selectedCustomer.state}
                    </Typography>
                  </div>
                )}
                {selectedCustomer.country && (
                  <div>
                    <Typography variant="body1">
                      <strong>Country:</strong> {selectedCustomer.country}
                    </Typography>
                  </div>
                )}
              </td>
            </tr>
          )}
          <tr>
            <td
              colSpan={2}
              style={{
                display: "flex",
                padding: "10px",
              }}
            ></td>
            <td style={{ padding: "10px" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td colSpan={5}>
              <div>
                <Card>
                  <CardContent
                    style={{ backgroundColor: "lightgrey", height: "3px" }}
                  >
                    <div style={{ marginTop: "-10px" }}>Products</div>
                  </CardContent>
                  <div>
                    <PheonixProductTable
                      rows={rows}
                      columns={columns}
                      handleInputChange={handleInputChange1}
                      handleAddRow={handleAddRow}
                      handleDeleteRow={handleDeleteRow}
                      fieldErrors={tableFieldErrors}
                      view={view}
                      edit={edit}
                      productsData={allproductsData}
                      setProductsData={setAllProductsData}
                    />
                  </div>
                </Card>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <div>
                <FormControl sx={{ width: "309px" }}>
                  <InputLabel id="demo-select-small-label">
                    <span style={{ color: "#181D8C" }}>Status</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={status}
                    disabled={view && !edit}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Accepted"}>Accepted</MenuItem>
                    <MenuItem value={"Rejected"}>Rejected</MenuItem>
                    <MenuItem value={"Draft"}>Draft</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <Autocomplete
                options={discountOptions}
                disablePortal
                disableClearable={true}
                disabled={view && !edit}
                onChange={(event, newValue) =>
                  handleDiscountChange(event, newValue)
                }
                value={QuotesData["Discount Amount"]}
                style={{ width: "309px" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      <>
                        <span style={{ color: "#181D8C" }}>
                          Discount Amount
                        </span>
                      </>
                    }
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            backgroundColor: "#EAEBFF",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            padding: "0 8px",
                            height: "100%",
                          }}
                        >
                          {params.InputProps.endAdornment}
                          <span style={{ marginLeft: "5px" }}> % </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  flexDirection: "row",
                  padding: "15px",
                  justifyContent: "space-between",
                  gap: "20px",
                }}
              >
                {currency === "Rupees" && (
                  <>
                    {selectedCustomer &&
                      selectedCustomer.gst_no &&
                      isIntraState(selectedCustomer.gst_no) && (
                        <>
                          <Typography variant="body1" sx={{ color: "#181D8C" }}>
                            <strong>CGST [9%]:</strong>{" "}
                            {getCurrencySymbol(currency)}
                            {totalTax.toFixed(2)}
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#181D8C" }}>
                            <strong>SGST [9%]:</strong>{" "}
                            {getCurrencySymbol(currency)}
                            {totalTax.toFixed(2)}
                          </Typography>
                        </>
                      )}
                    {selectedCustomer &&
                      selectedCustomer.gst_no &&
                      !isIntraState(selectedCustomer.gst_no) && (
                        <>
                          <Typography variant="body1" sx={{ color: "#181D8C" }}>
                            <strong>IGST [18%]:</strong>{" "}
                            {getCurrencySymbol(currency)}
                            {(totalTax * 2).toFixed(2)}
                          </Typography>
                        </>
                      )}
                    {selectedCustomer && !selectedCustomer.gst_no && (
                      <>
                        <Typography variant="body1" sx={{ color: "#181D8C" }}>
                          <strong>CGST [9%]:</strong>{" "}
                          {getCurrencySymbol(currency)}
                          {totalTax.toFixed(2)}
                        </Typography>
                        <Typography variant="body1" sx={{ color: "#181D8C" }}>
                          <strong>SGST [9%]:</strong>{" "}
                          {getCurrencySymbol(currency)}
                          {totalTax.toFixed(2)}
                        </Typography>
                      </>
                    )}
                  </>
                )}
                <Typography variant="body1" sx={{ color: "#181D8C" }}>
                  <strong>Total Amount:</strong> {getCurrencySymbol(currency)}
                  {totalAmount.toFixed(2)}
                </Typography>
              </div>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="textarea"
                disabled={view && !edit}
                value={QuotesData["Customer Notes"]}
                placeholder="Customer Notes"
                name="Customer Notes"
                label={
                  <>
                    <span style={{ color: "#181D8C" }}>Customer Notes</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </>
                }
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Customer Notes"]}
                helperText={fieldErrors["Customer Notes"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={2}
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  color: "#000000",
                  fontWeight: 600,
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  marginBottom: "5px",
                }}
              >
                {TEXT_MESSAGES.TERMS}
                <span style={{ color: "red", marginLeft: "5px" }}>*</span>
              </Typography>
              <PheonixTextField
                id="outlined-error"
                type="textarea"
                disabled={view && !edit}
                value={QuotesData["Terms and Conditions"]}
                placeholder="Terms and Conditions"
                name="Terms and Conditions"
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Terms and Conditions"]}
                helperText={fieldErrors["Terms and Conditions"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={3}
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};
export default QuoteTable;
