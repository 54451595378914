import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixProductTableProps from "../interfaces/PheonixProductTableprops";
import { GET_ALL_PRODUCTS } from "../graphql/query";
import { api } from "../api";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";
interface ProductData {
  [key: string]: any;
}

const initialQuoteData: ProductData = {
  "Product Name": "",
  "Product Plan": "",
  "Billing Cycle": "",
  Tax: "",
  Rate: 0,
  "HSN Number": "",
};

const PheonixProductTable: React.FC<PheonixProductTableProps> = ({
  columns,
  rows,
  view,
  edit,
  handleInputChange,
  fieldErrors,
}) => {
  const planOptions = ["GST 18%", "Non-Taxable"];
  const newOptions = ["Basic", "Medium", "Premium", "Pro", "Custom"];
  const billOptions = ["Monthly", "Quarterly", "Half yearly", "Yearly"];
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [newProductName, setNewProductName] = useState("");
  const [allproductsData, setAllProductsData] =
    useState<ProductData>(initialQuoteData);
  const [productOptionsAPI, setProductOptions] = useState([]);
  const [error, setError] = useState("");

  const handleAddProduct = () => {
    setIsAddingProduct(true);
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_PRODUCTS,
        },
        config
      );
      const products = response.data.data.getallproducts;
      setAllProductsData(products);
      const productOptions = products.map((product: any) => product.name);
      setProductOptions(productOptions);
    } catch (error: any) {
      setError(error.message);
    }
  };
  const handleSaveProduct = (
    index: number,
    field: string,
    newProductName: string
  ) => {
    handleInputChange(index, field, newProductName);
    setIsAddingProduct(false);
  };

  const handleCancelAddProduct = () => {
    setIsAddingProduct(false);
    setNewProductName("");
  };

  const renderCell = (column: any, row: any, index: any) => {
    const productOptions = [
      ...productOptionsAPI,
      { label: "Add Product", value: "addProduct" },
    ];

    if (column.render) {
      return column.render(index);
    }

    if (
      column.field === "Rate" ||
      column.field === "Quantity" ||
      column.field === "Amount" ||
      column.field === "Product Details" ||
      column.field === "HSN Number"
    ) {
      if (column.field === "Product Details") {
        if (isAddingProduct) {
          return (
            <div>
              <TextField
                label="Product Name"
                value={newProductName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewProductName(e.target.value)
                }
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "15px",
                  },
                }}
                style={{ width: "150px" }}
              />
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleSaveProduct(index, column.field, newProductName)
                  }
                  style={{ marginRight: "8px" }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelAddProduct}
                >
                  Cancel
                </Button>
              </div>
            </div>
          );
        }
        return (
          <Autocomplete
            disablePortal
            options={productOptions}
            size="small"
            disabled={view && !edit}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.label
            }
            renderInput={(params) => <TextField {...params} label="" />}
            renderOption={(props, option) => {
              if (option.value === "addProduct") {
                return (
                  <li {...props} key="add-product">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddProduct()}
                      fullWidth
                    >
                      + Add Product
                    </Button>
                  </li>
                );
              }
              return (
                <li {...props} key={option}>
                  {option}
                </li>
              );
            }}
            onChange={(event, newValue) => {
              if (newValue?.value !== "addProduct") {
                handleInputChange(index, column.field, newValue);
              }
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "15px",
              },
            }}
            value={row[column.field] || null}
            style={{ width: "150px" }}
          />
        );
      }

      return (
        <TextField
          type="text"
          value={row[column.field] || ""}
          disabled={view && !edit}
          placeholder={
            column.field === "Rate" || column.field === "Amount"
              ? "0.00"
              : column.field === "Quantity"
              ? "0"
              : column.field === "Product Details"
              ? "Enter Product"
              : "Enter HSN"
          }
          size="small"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChange(index, column.field, e.target.value)
          }
          error={!!fieldErrors?.[index]?.[column.field]}
          helperText={fieldErrors?.[index]?.[column.field] || ""}
          sx={{
            padding: "4px",
            marginLeft:
              column.field === "Product Details" ||
              column.field === "Amount" ||
              column.field === "Tax"
                ? "-14%"
                : "-10%",
            marginRight: column.field === "Rate" ? "-15px" : "0px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
            },
          }}
          inputProps={{
            style: {
              textAlign: column.field === "Product Details" ? "left" : "right",
            },
          }}
          InputProps={{
            style: { textAlign: "right" },
          }}
        />
      );
    }

    if (
      column.field === "Tax" ||
      column.field === "Product Plan" ||
      column.field === "Billing Cycle"
    ) {
      return (
        <Autocomplete
          disablePortal
          options={
            column.field === "Tax"
              ? planOptions
              : column.field === "Product Plan"
              ? newOptions
              : billOptions
          }
          size="small"
          disabled={view && !edit}
          renderInput={(params) => <TextField {...params} label="" />}
          onChange={(event, newValue) =>
            handleInputChange(index, column.field, newValue)
          }
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "15px",
            },
          }}
          value={row[column.field] || null}
          style={{ width: "130px", marginLeft: "14%" }}
        />
      );
    }

    return row[column.field];
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow style={{ fontFamily: "Roboto" }}>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                style={{
                  width:
                    column.field === "Rate" || column.field === "HSN Number"
                      ? "120px"
                      : column.field === "Quantity" || column.field === "Tax"
                      ? "80px"
                      : column.field === "Amount"
                      ? "120px"
                      : column.field === "Billing Cycle" ||
                        column.field === "Product Plan"
                      ? "130px"
                      : column.field === "Product Details"
                      ? "170px"
                      : "100px",
                  padding: "10px",
                  fontSize: "16px",
                  color: "black",
                  fontFamily: "Roboto",
                  fontWeight: 600,

                  textAlign:
                    column.field === "Product Details"
                      ? "left"
                      : column.field === "Tax" ||
                        column.field === "Billing Cycle" ||
                        column.field === "Quantity" ||
                        column.field === "Rate"
                      ? "right"
                      : "center",
                }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "16px",
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  style={{
                    width:
                      column.field === "Rate" || column.field === "HSN Number"
                        ? "120px"
                        : column.field === "Quantity" || column.field === "Tax"
                        ? "80px"
                        : column.field === "Amount"
                        ? "120px"
                        : column.field === "Billing Cycle" ||
                          column.field === "Product Plan"
                        ? "130px"
                        : column.field === "Product Details"
                        ? "170px"
                        : "100px",
                    fontFamily: "Roboto",
                    color: "black",
                    textAlign:
                      column.field === "Product Details" ? "left" : "center",
                  }}
                >
                  {renderCell(column, row, index)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PheonixProductTable;
