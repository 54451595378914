import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Typography,
  Modal,
  Stack,
  styled,
  Button,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { GET_ALL_CUSTOMERS } from "../graphql/query";
import PheonixTextField from "./PheonixTextField";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { api } from '../api';
import OrderProps from "../interfaces/OrderProps";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

interface Invoice {
  id: string;
  invoice_no: string;
}

const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
});
interface CustomerOption {
  id: string;
  name: string;
  email: string;
  number?: string;
  gst_no?: string;
  address?: string;
  company?:string;
  customerNo?:string;
}
const customer: CustomerOption = {
  id: "",
  name: "",
  email: "",
  number: "",
  gst_no: "",
  address: "",
  company:"",
  customerNo:""
};

const OrderModal = <T extends object>({
  open,
  onClose,
  onConfirm,
  fields,
  formData,
  setFormData,
  handleConfirm,
  edit,
  selectedProducts,
  setSelectedProducts,
}: OrderProps<T>) => {
  const [switchSelected, setSwitchSelected] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [invoiceNumberOptions, setInvoiceNumberOptions] = useState<string[]>(
    []
  );
  const [customerOptions, setCustomerOptions] = useState<CustomerOption[]>([
    customer,
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const AutocompleteStyle = isMobile
    ? { marginLeft: "5px" }
    : { marginLeft: "0px" };

  const [error, setError] = useState("");

  useEffect(() => {
    validateForm();
  }, [formData]);

  useEffect(() => {
    fetchCustomers();
    setFieldErrors({})
  }, []);

  useEffect(() => {
    setFieldErrors({})
  }, [open]);

  const fetchCustomers = async () => {
    const token = localStorage.getItem("token"); 
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(api.baseUrl, {
        query: GET_ALL_CUSTOMERS,
      },config
     );
      const customers = response.data.data.getAllUsers;
      const customersClient = customers.filter(
        (customer: any) => customer.user_type === "2"
      );
      if (customers) {
        const customerOptions = customersClient.map(
          (customer: {
            _id: string;
            customer_no:string;
            basic_info: {
              firstname: string;
              email: string;
              phone_number: string;
              shipping_address: { address_line1: string };
              company_name:string;
            };
            billing_info: { GST_no: string };
          }) => ({
            id: customer._id,
            name: customer.basic_info.firstname,
            email: customer.basic_info.email,
            number: customer.basic_info.phone_number,
            gst_no: customer.billing_info.GST_no,
            address: customer.basic_info.shipping_address.address_line1,
            company:customer.basic_info.company_name,
            customerNo:customer.customer_no
          })
        );
        setCustomerOptions(customerOptions);
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setIsConfirmDisabled(hasErrors);
  }, [fieldErrors]);

  const validateForm = () => {
    let isAnyFieldEmpty = false;
    fields.forEach((field) => {
      const fieldValue = (formData as { [key: string]: string })[
        field.label
      ].trim();
      if (!fieldValue) {
        isAnyFieldEmpty = true;
      } else {
        validateField(field.label);
      }
    });
    setIsButtonDisabled(isAnyFieldEmpty);
  };

  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };

  const handleFieldBlur = (fieldLabel: string) => {
    validateField(fieldLabel);
  };

  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as { [key: string]: string })[fieldLabel];
    switch (fieldLabel) {
      case "Company Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.COMPANY_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Customer Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.COSTOMER_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Contact Number":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_REQ,
          }));
        } else if (!/^[0-9]*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_ERROR,
          }));
        } else if (fieldValue.length !== 10) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.NUMBER_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Contact Email ID":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_REQ,
          }));
        } else if (!TEXT_MESSAGES.EMAIL_REG.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.MAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Product/Service":
        break;
      case "Plan":
        break;
      case "Order Specific Details":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.ORDER_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Actual Amount":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.AMT_ERR,
          }));
        } else if (!/^\d*\.?\d*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Proposed Amount":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.AMT_ERR,
          }));
        } else if (!/^\d*\.?\d*$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.AMOUNT_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      default:
        break;
    }
  };

  const handleDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setFormData((prevState) => ({
      ...prevState,
      ["Renewal Date"]: formattedDate,
    }));
  };
  const handleNameChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    const { id, name, email, number, gst_no, address,company } = newValue;
    setFormData((prevData) => ({
      ...prevData,
      "Customer Name": name,
      "Customer Id": id,
      "Contact Email ID": email,
      "Contact Number": number || "",
      "Company Name":company || "",
    }));
  };
  const handleProductChange = (value: string[]) => {
    setSelectedProducts(value);
    setFormData((prevData) => ({
      ...prevData,
      ["Product/Service"]: value.length > 0 ? value.join(", ") : "",
    }));
  };

  const productServiceOptions = [
    "Web Hosting",
    "Dev-ops",
    "Web Applications",
    "Web Design",
    "Mobile app",
    "Web Maintence",
    "ERP",
    "Digital Marketing",
    "SCM",
    "Tech Support",
  ];
  const planOptions = ["Plan 1", "Plan 2", "Plan 3"];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100vh",
          width: 351,
          boxShadow: 24,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "5%",
          }}
        >
          <div
            style={{
              width: "38px",
              height: "38px",
              backgroundColor: "#EEEFFF",
              borderRadius: "15px",
              display: "flex",
              alignSelf: "flex-start",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "5%",
            }}
          >
            <AddShoppingCartIcon sx={{ color: "#181D8C" }} />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "2%" }}
          >
            <Typography style={{ fontSize: "16px" }}>
              {edit ? TEXT_MESSAGES.EDIT_ORDER : TEXT_MESSAGES.CREATE_ORDER}
            </Typography>
          </div>
          <IconButton
            onClick={onClose}
            style={{
              position: "absolute",
              right: "15px",
              width: "16px",
              height: "16px",
              padding: "5px",
              color: "#5B616B",
              marginTop: "2%",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "#CBC2E9",
            marginTop: "5%",
          }}
        ></div>
        <div style={{  overflowY: "auto" }}>
          <Stack spacing={1} direction="column">
            {fields.map((field, index) => (
              <Item key={index}>
                {field.label === "Product/Service" ? (
                  <div>
                    <Autocomplete
                      multiple
                      disablePortal
                      options={productServiceOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.label}
                          error={!!fieldErrors[field.label]}
                          helperText={fieldErrors[field.label]}
                          onBlur={() => handleFieldBlur(field.label)}
                        />
                      )}
                      onChange={(event, value) => {
                        handleProductChange(value);
                      }}
                      style={{ width: 319, marginLeft: isMobile ? "2%" : "0%" }}
                      value={selectedProducts}
                    />
                  </div>
                ) : field.label === "Customer Name" ? (
                  <div>
                    <Autocomplete
                      disablePortal
                      options={customerOptions}
                      renderInput={(params) => (
                        <TextField {...params} label="Customer Name" />
                      )}
                      getOptionLabel={(option) => option.customerNo ? `${option.name} (${option.customerNo})` : option.name}
                      disableClearable={true}
                      onChange={(event, newValue) =>
                        handleNameChange(event, newValue)
                      }
                      value={
                        customerOptions.find(
                          (option) =>
                            option.name ===
                            (formData as { [key: string]: string })[
                              "Customer Name"
                            ]
                        ) ||  { id: "", name: "",email:"",customerNo:"" }
                      }
                      style={{
                        marginLeft: isMobile ? "5px" : "0px",
                        width: 319,
                      }}
                    />
                  </div>
                ) : field.label === "Plan" ? (
                  <div>
                    <Autocomplete
                      disablePortal
                      options={planOptions}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={field.label}
                          error={!!fieldErrors[field.label]}
                          helperText={fieldErrors[field.label]}
                          onBlur={() => handleFieldBlur(field.label)}
                        />
                      )}
                      onChange={(event, value) =>
                        handleFieldChange(field.label, value ?? "")
                      }
                      style={{ width: 319, marginLeft: isMobile ? "2%" : "0%" }}
                      value={
                        (formData as { [key: string]: string })[field.label]
                      }
                    />
                  </div>
                ) : field.label === "Renewal Date" ? (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Renewal Date"
                      format="DD/MM/YYYY"
                      value={
                        (formData as { [key: string]: string })["Renewal Date"]
                          ? dayjs(
                              (formData as { [key: string]: string })[
                                "Renewal Date"
                              ],
                              "DD/MM/YYYY"
                            )
                          : null
                      }
                      onChange={handleDateChange}
                      disablePast
                      sx={{
                        width: 319,
                      }}
                    />
                  </LocalizationProvider>
                ) : (
                  <PheonixTextField
                    id="outlined-error"
                    type="text"
                    placeholder={field.label}
                    label={field.label}
                    value={(formData as { [key: string]: string })[field.label]}
                    error={!!fieldErrors[field.label]}
                    helperText={fieldErrors[field.label]}
                    onBlur={() => handleFieldBlur(field.label)}
                    onChange={(e) =>
                      handleFieldChange(field.label, e.target.value)
                    }
                    variant="outlined"
                    style={{ width: "319px" }}
                    readonly={(field.label === 'Contact Number' || field.label ==='Company Name' ||field.label === 'Contact Email ID') ? true : false}
                  />
                )}
              </Item>
            ))}
          </Stack>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignSelf: "flex-end",
            marginTop: "auto",
            flexDirection: "column",
          }}
        >
          <div
            style={{ height: "2px", width: "100%", backgroundColor: "#CBC2E9" }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <Button
              onClick={onClose}
              style={{
                color: "grey",
                backgroundColor: "white",
                border: "1px solid #1C1B1F",
                width: "125px",
                height: "36px",
                borderRadius: "5px",
                marginLeft: "2%",
              }}
            >
              {TEXT_MESSAGES.CANCEL}
            </Button>
            <Button
              disabled={isButtonDisabled || isConfirmDisabled}
              onClick={handleConfirm}
              sx={{
                color: "white",
                border: "1px solid",
                backgroundColor:
                  isButtonDisabled || isConfirmDisabled
                    ? "lightgray"
                    : "#181D8C",
                width: "125px",
                height: "36px",
                borderRadius: "5px",
                marginRight: "2%",
                "&:hover": { backgroundColor: "#181D8C" },
                "&:active": { backgroundColor: "#181D8C" },
              }}
            >
              {edit ? TEXT_MESSAGES.UPDATE_TXT : TEXT_MESSAGES.SAVE}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default OrderModal;
