import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/Analytics";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/PWLOGO.png";
import logo1 from "../assets/PWLOGO2.png";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import SidebarItem from "../Components/PheonixSideBar";
import SidebarProps from "../interfaces/SidebarpRops";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Tooltip from "@mui/material/Tooltip";
import { TEXT_MESSAGES } from "../const";
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const location = useLocation();
  const theme = useTheme();
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const handleMenuClick = () => {
    onToggle();
  };
  const iconComponent = (
    <ExpandCircleDownIcon
      style={{
        width: "28px",
        height: "28px",
        marginRight: "-15px",
        zIndex: 50,
        transform: isOpen ? "rotate(90deg)" : "rotate(-90deg)",
        marginBottom: "5px",
        position: "absolute",
        top: "10px",
        right: isOpen ? "5px" : "-38px",
        color: "white",
        backgroundColor: "#344DA3",
      }}
    />
  );
  let menuItems;
  if (loggedInUser === "1") {
    menuItems = [
      {
        icon: isOpen ? (
          <HomeOutlinedIcon />
        ) : (
          <Tooltip title="Home">
            <HomeOutlinedIcon />
          </Tooltip>
        ),
        text: "Home",
        to: "/home",
      },
      {
        icon: isOpen ? (
          <AccountCircleOutlinedIcon />
        ) : (
          <Tooltip title="Customer">
            <AccountCircleOutlinedIcon />
          </Tooltip>
        ),
        text: "Customers",
        to: "/customer",
      },

      {
        icon: isOpen ? (
          <ListAltOutlinedIcon />
        ) : (
          <Tooltip title="Orders">
            <ListAltOutlinedIcon />
          </Tooltip>
        ),
        text: "Orders",
        to: "/orders",
      },
      {
        icon: isOpen ? (
          <RequestQuoteOutlinedIcon />
        ) : (
          <Tooltip title="Quotation">
            <RequestQuoteOutlinedIcon />
          </Tooltip>
        ),
        text: "Quotations",
        to: "/quotation",
      },
      {
        icon: isOpen ? (
          <ReceiptOutlinedIcon />
        ) : (
          <Tooltip title="Invoice">
            <ReceiptOutlinedIcon />
          </Tooltip>
        ),
        text: "Invoices",
        to: "/invoice",
      },
      {
        icon: isOpen ? (
          <AnalyticsOutlinedIcon />
        ) : (
          <Tooltip title="Report">
            <AnalyticsOutlinedIcon />
          </Tooltip>
        ),
        text: "Reports",
        to: "/report",
      },
    ];
  } else {
    menuItems = [
      {
        icon: isOpen ? (
          <HomeOutlinedIcon />
        ) : (
          <Tooltip title="Home">
            <HomeOutlinedIcon />
          </Tooltip>
        ),
        text: "Home",
        to: "/dashboard",
      },
      {
        icon: isOpen ? (
          <ListAltOutlinedIcon />
        ) : (
          <Tooltip title="Orders">
            <ListAltOutlinedIcon />
          </Tooltip>
        ),
        text: "Orders",
        to: "/orders",
      },
      {
        icon: isOpen ? (
          <CategoryIcon />
        ) : (
          <Tooltip title="Product/Service">
            <CategoryIcon />
          </Tooltip>
        ),
        text: "Products/Services",
        to: "/products",
      },
      {
        icon: isOpen ? (
          <RequestQuoteOutlinedIcon />
        ) : (
          <Tooltip title="Quotation">
            <RequestQuoteOutlinedIcon />
          </Tooltip>
        ),
        text: "Quotations",
        to: "/quotation",
      },
      {
        icon: isOpen ? (
          <ReceiptOutlinedIcon />
        ) : (
          <Tooltip title="Invoice">
            <ReceiptOutlinedIcon />
          </Tooltip>
        ),
        text: "Invoices",
        to: "/invoice",
      },
      {
        icon: isOpen ? (
          <AnalyticsOutlinedIcon />
        ) : (
          <Tooltip title="Report">
            <AnalyticsOutlinedIcon />
          </Tooltip>
        ),
        text: "Reports",
        to: "/report",
      },
    ];
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isOpen ? 280 : 140,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          background: "linear-gradient(to bottom, #27489C, #A974DD)",
          fontSize: "16px",
          color: "white",
          width: isOpen ? 280 : 140,
          boxSizing: "border-box",
          transition: "width 0.2s ease-in-out, visibility 0.2s linear",
          overflow: "hidden",
        },
      }}
      open={isOpen}
      onClose={onToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div
        onClick={handleMenuClick}
        style={
          isOpen
            ? {
                position: "absolute",
                top: "20px",
                right: "0px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }
            : {
                position: "absolute",
                top: "27px",
                left: "25px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }
        }
      >
        {isOpen ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                height: "81px",
                width: "153px",
                marginBottom: "5px",
                marginRight: "55px",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={logo1}
              alt="Logo1"
              style={{ height: "66px", width: "72px", marginBottom: "5px" }}
            />
          </div>
        )}
        {iconComponent}
      </div>
      <List
        sx={{
          top: "135px",
          left: isOpen ? "20px" : "40px",
          width: isOpen ? "100%" : "40%",
        }}
      >
        {menuItems.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            text={isOpen ? item.text : undefined}
            to={item.to}
            selected={
              ((location.pathname.startsWith("/quotatio") && item.text === "Quotations") ||
              (location.pathname.startsWith("/invoice") && item.text === "Invoices") ||
              (location.pathname.startsWith("/order") && item.text === "Orders")) ||
              location.pathname === item.to
            }
          />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
