import React, { useState, useEffect } from "react";
import axios from "axios";
import PheonixCard from "../Components/PheonixCard";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { api } from "../api";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
  fabClasses,
  Autocomplete,
  TextField,
} from "@mui/material";
import PheonixTextField from "../Components/PheonixTextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
interface FieldErrors {
  [key: string]: string;
}

const Profilepage: React.FC = () => {
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const userId = localStorage.getItem("userId");
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState<any>({});
  const [isVisible, setIsVisible] = useState(true);
  const [isVisible1, setIsVisible1] = useState(true);
  const [isVisible2, setIsVisible2] = useState(true);
  const [updateeditedData, setUpdateEditedData] = useState<any>({});
  const [succMessage, setSuccMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});
  const [confirmDisable, setConfirmDisable] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const toggleVisibility1 = () => {
    setIsVisible1(!isVisible1);
  };

  const toggleVisibility2 = () => {
    setIsVisible2(!isVisible2);
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      fetchUserData();
    }
  }, []);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const fetchUserData = async () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: `
                      query GetUserById($userId: ID!) {
                          getUserById(id: $userId) {
                              user_id
                              basic_info {
                              company_name
                              customer_fullname
                              contact_number
                              email
                          }
                          contact_info {
                              address
                              city
                              state
                              country
                              pincode
                          }
                        billing_info {
                                GST_type
                                GST_no
                                currency
                            }
                          }
                      }
                  `,
          variables: {
            userId: userId,
          },
        },
        config
      );
      setUserData(response.data.data.getUserById);
      setLoading(false);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  const editUser = async (
    id: string,
    basic_info: {
      customer_fullname: string;
      email: string;
      contact_number: string;
      company_name: string;
    },
    contact_info: {
      address: string;
      city: string;
      state: string;
      pincode: string;
      country: string;
    },
    billing_info: {
      GST_type: string;
      GST_no: string;
      currency: string;
    }
  ) => {
    const query = `
      mutation UpdateUser(
        $id: ID!,
        $basic_info: BasicNewInfoInput,
        $contact_info: ContactNewInfoInput,
        $billing_info: BillingNewInfoInput,

      ) {
        updateUser(
          id: $id,
          basic_info: $basic_info,
          contact_info: $contact_info,
          billing_info: $billing_info,
        ) {
          success
        message
        userId
        }
      }
    `;

    const variables = {
      id,
      basic_info,
      contact_info,
      billing_info,
    };

    try {
      const response = await axios.post(api.baseUrl, {
        query,
        variables,
      });

      const userData = response.data.data.updateUser;
      if (userData?.success && userData?.userId) {
        setSnackbarOpen(true);
        setSuccMessage(ERROR_MESSAGES.PROFILE_SUCCESS);
        setTimeout(() => {
          setSnackbarOpen(false);
          setSuccMessage("");
          fetchUserData();
        }, 3000);
      } else {
        setSnackbarOpen(true);
        setSuccMessage(ERROR_MESSAGES.PROFILE_FAIL);
        setTimeout(() => {
          setSnackbarOpen(false);
          setSuccMessage("");
        }, 3000);
      }
    } catch (error) {
      setSnackbarOpen(true);
      setSuccMessage(ERROR_MESSAGES.PROFILE_FAIL);
      setTimeout(() => {
        setSnackbarOpen(false);
        setSuccMessage("");
      }, 3000);
    }
  };

  const isMobileView = window.innerWidth <= 768;
  const customLabels: Record<string, string> = {
    customer_fullname: "Customer Full Name",
    email: "Contact Email ID",
    company_name: "Company Name",
    contact_number: "Phone Number",
    contact_name: "Contact Name",
    contact_no: "Contact Number",
    contact_email: "Email",
    address: "Address",
    city: "City",
    state: "State",
    country: "Country",
    pincode: "Pin Code",
    GST_type: "GST Type",
    GST_no: "GST Number",
    currency: "Currency",
  };

  const basicInfoLabels: Record<string, string> = {
    customer_fullname: "Customer Full Name",
    email: "Contact Email ID",
    company_name: "Company Name",
    contact_number: "Contact Number",
  };

  const billingInfoLabels: Record<string, string> = {
    GST_type: "GST Type",
    GST_no: "GST Number",
    currency: "Currency",
  };

  const gstTypeOptions = [
    { value: "Register", label: "Register" },
    { value: "Unregister", label: "Unregister" },
  ];

  const currencyOptions = [
    { value: "USD", label: "USD" },
    { value: "INR", label: "INR" },
    { value: "EUR", label: "EUR" },
  ];

  const handleEdit = () => {
    setIsEditMode(true);
    setUpdateEditedData(userData);
    setEditedData(userData);
  };

  const handleChange1 = (section: string, key: string, value: string) => {
    setUpdateEditedData((prevData: any) => {
      const updatedData = { ...prevData };
      const sections = section.split(".");
      let target = updatedData;

      for (let i = 0; i < sections.length - 1; i++) {
        target = target[sections[i]];
      }

      target[sections[sections.length - 1]][key] = value;

      return { ...updatedData };
    });
  };

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setConfirmDisable(hasErrors);
  }, [fieldErrors]);

  const handleChange = (key: any, value: string) => {
    const updatedData = {
      ...updateeditedData,
      basic_info: {
        ...updateeditedData.basic_info,
        [key]: value,
      },
      billing_info: {
        ...updateeditedData.billing_info,
        [key]: value,
      },
      contact_info: {
        ...updateeditedData.contact_info,
        [key]: value,
      },
    };
    setUpdateEditedData(updatedData);

    if (value === "") {
      const { [key]: omit, ...rest } = updatedData.basic_info;
      setUpdateEditedData({ ...updatedData, basic_info: rest });
    }
    if (key === "contact_number" || key === "contact_no") {
      if (!/^[0-9]*$/.test(value)) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.NUMBER_ONLY,
        }));
      } else if (value.length !== 10) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.NUMBER_10_LENGTH,
        }));
      } else {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "",
        }));
      }
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [key]: "",
      }));
    }
    if (key === "email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.INVALID_EMAIL_FORMAT,
        }));
      } else {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      }
    }
    if (key === "GST_no") {
      if (!value) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.GST_NO_IS_REQUIRED,
        }));
      } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.GST_NO_SHOULD,
        }));
      } else if (value.length !== 15) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [key]: ERROR_MESSAGES.GST_NO_SHOULD_1,
        }));
      } else {
        setFieldErrors((prevErrors) => ({ ...prevErrors, [key]: "" }));
      }
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    setConfirmDisable(false);
    setFieldErrors({});
  };

  const handleSave = async () => {
    const id = userData?.id || userId;

    if (!id) {
      console.error("User ID is missing");
      return;
    }

    await editUser(
      id,
      {
        customer_fullname: updateeditedData.basic_info.customer_fullname || "",
        email: updateeditedData.basic_info.email || "",
        contact_number: updateeditedData.basic_info.contact_number || "",
        company_name: updateeditedData.basic_info.company_name || "",
      },
      {
        address: updateeditedData.contact_info?.address || "",
        city: updateeditedData.contact_info?.city || "",
        state: updateeditedData.contact_info?.state || "",
        pincode: updateeditedData.contact_info?.pincode || "",
        country: updateeditedData.contact_info?.country || "",
      },
      {
        GST_type: updateeditedData.billing_info?.GST_type || "",
        GST_no: updateeditedData.billing_info?.GST_no || "",
        currency: updateeditedData.billing_info?.currency || "",
      }
    );
    setIsEditMode(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: isMobileView ? "0" : "40px",
        marginTop: "-1%",
      }}
    >
      <Typography
        style={{
          fontSize: "18px",
          fontFamily: "Roboto",
          color: "#181D8C",
          fontWeight: 600,
          textAlign: "left",
          lineHeight: "21px",
        }}
      >
        {TEXT_MESSAGES.PROFILE_TXT}
      </Typography>
      <PheonixBreadcrumbs />
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      {userData && userData !== null && (
        <>
          <Paper
            sx={{
              maxHeight: "70vh",
              overflowY: "auto",
              marginTop: "1%",
            }}
          >
            <Box
              sx={{
                boxShadow: "0px 4px 2px -2px rgba(0,0,0,0.2)",
                padding: 1.5,
                borderRadius: "7.5px 0px 0px 0px",
                height: "50px",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: "#032C43",
                  fontWeight: "bold",
                  marginLeft: "1.2%",
                  marginTop: "0.7%",
                }}
              >
                {TEXT_MESSAGES.PROFILE}
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "2%", marginTop: "3%" }}>
              <PheonixCard
                isMobileView={isMobileView}
                title={TEXT_MESSAGES.BASIC_INFO}
                buttonDisabled={false}
                onClickButton={isEditMode ? handleSave : handleEdit}
              >
                <IconButton
                  onClick={toggleVisibility}
                  sx={{ marginLeft: "95%", marginTop: "-2%" }}
                >
                  {isVisible ? (
                    <RemoveIcon sx={{ color: "#69696E" }} />
                  ) : (
                    <AddIcon sx={{ color: "#69696E" }} />
                  )}
                </IconButton>
                {isVisible && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        marginBottom: "1rem",
                      }}
                    >
                      <div
                        key="company_name"
                        style={{
                          marginLeft: isMobileView ? "0px" : "3px",
                          marginBottom: "1rem",
                          marginRight: "2.5rem",
                          minWidth: "200px",
                        }}
                      >
                        {isEditMode ? (
                          <PheonixTextField
                            id="company_name"
                            type="text"
                            value={
                              updateeditedData.basic_info["company_name"] || ""
                            }
                            label={
                              customLabels["company_name"] || "COMPANY NAME"
                            }
                            onChange={(e) =>
                              handleChange("company_name", e.target.value)
                            }
                            disabled={!isEditMode}
                            placeholder={`Enter ${basicInfoLabels["company_name"]}`}
                          />
                        ) : (
                          <>
                            <p
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              {basicInfoLabels["company_name"]}
                            </p>
                            <div
                              style={{
                                fontWeight: 400,
                                color: "#000000",
                                fontSize: "14px",
                              }}
                            >
                              {(userData.basic_info[
                                "company_name"
                              ] as string) || "-"}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {["customer_fullname", "contact_number", "email"].map(
                        (key) => (
                          <div
                            key={key}
                            style={{
                              marginLeft: isMobileView ? "0px" : "3px",
                              marginBottom: "1rem",
                              marginRight: "2.5rem",
                              minWidth: "200px",
                            }}
                          >
                            {isEditMode ? (
                              <PheonixTextField
                                id={key}
                                type="text"
                                value={updateeditedData.basic_info[key] || ""}
                                label={
                                  customLabels[key] ||
                                  key.split("_").join(" ").toUpperCase()
                                }
                                onChange={(e) =>
                                  handleChange(key, e.target.value)
                                }
                                disabled={!isEditMode}
                                placeholder={`Enter ${basicInfoLabels[key]}`}
                                error={!!fieldErrors[key]}
                                helperText={fieldErrors[key]}
                              />
                            ) : (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                >
                                  {basicInfoLabels[key]}
                                </p>
                                <div
                                  style={{
                                    fontWeight: 400,
                                    color: "#000000",
                                    fontSize: "14px",
                                  }}
                                >
                                  {(userData.basic_info[key] as string) || "-"}
                                </div>
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </PheonixCard>
            </Box>

            <Box sx={{ marginLeft: "2%", marginTop: "3%" }}>
              <PheonixCard
                isMobileView={window.innerWidth <= 768}
                title={TEXT_MESSAGES.CONTACT_INFO}
                buttonDisabled={false}
                onClickButton={() => {}}
              >
                <IconButton
                  onClick={toggleVisibility1}
                  sx={{ marginLeft: "95%", marginTop: "-2%" }}
                >
                  {isVisible1 ? (
                    <RemoveIcon sx={{ color: "#69696E" }} />
                  ) : (
                    <AddIcon sx={{ color: "#69696E" }} />
                  )}
                </IconButton>
                {isVisible1 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                        marginBottom: "1rem",
                      }}
                    >
                      <div
                        key="address"
                        style={{
                          marginLeft: window.innerWidth <= 768 ? "0px" : "3px",
                          flex: "1 1 100%",
                          minWidth: "200px",
                        }}
                      >
                        {!isEditMode ? (
                          <>
                            <p
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              {customLabels["address"]}
                            </p>
                            <div
                              style={{
                                fontWeight: 400,
                                color: "#000000",
                                fontSize: "14px",
                              }}
                            >
                              <span>
                                {userData.contact_info["address"] || "-"}
                              </span>
                            </div>
                          </>
                        ) : (
                          <TextField
                            id="address"
                            multiline
                            minRows={2}
                            value={
                              updateeditedData.contact_info["address"] || ""
                            }
                            label={customLabels["address"]}
                            onChange={(e) =>
                              handleChange("address", e.target.value)
                            }
                            placeholder={`Enter ${customLabels["address"]}`}
                          />
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                        marginBottom: "1rem",
                      }}
                    >
                      {Object.keys(userData.contact_info)
                        .filter((key) => key !== "address" && key !== "pincode")
                        .map((key) => (
                          <div
                            key={key}
                            style={{
                              marginLeft:
                                window.innerWidth <= 768 ? "0px" : "3px",
                              flex: "1 1 200px",
                              minWidth: "200px",
                            }}
                          >
                            {!isEditMode ? (
                              <>
                                <p
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                >
                                  {customLabels[key]}
                                </p>
                                <div
                                  style={{
                                    fontWeight: 400,
                                    color: "#000000",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span>
                                    {userData.contact_info[key] || "-"}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <PheonixTextField
                                id={key}
                                type="text"
                                value={updateeditedData.contact_info[key] || ""}
                                label={customLabels[key]}
                                onChange={(e) =>
                                  handleChange(key, e.target.value)
                                }
                                disabled={!isEditMode}
                                placeholder={`Enter ${customLabels[key]}`}
                                error={!!fieldErrors[key]}
                                helperText={fieldErrors[key]}
                              />
                            )}
                          </div>
                        ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        key="pincode"
                        style={{
                          marginLeft: window.innerWidth <= 768 ? "0px" : "3px",
                          flex: "1 1 200px",
                          minWidth: "200px",
                        }}
                      >
                        {!isEditMode ? (
                          <>
                            <p
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              {customLabels["pincode"]}
                            </p>
                            <div
                              style={{
                                fontWeight: 400,
                                color: "#000000",
                                fontSize: "14px",
                              }}
                            >
                              <span>
                                {userData.contact_info["pincode"] || "-"}
                              </span>
                            </div>
                          </>
                        ) : (
                          <PheonixTextField
                            id="pincode"
                            type="text"
                            value={
                              updateeditedData.contact_info["pincode"] || ""
                            }
                            label={customLabels["pincode"]}
                            onChange={(e) =>
                              handleChange("pincode", e.target.value)
                            }
                            disabled={!isEditMode}
                            placeholder={`Enter ${customLabels["pincode"]}`}
                            error={!!fieldErrors["pincode"]}
                            helperText={fieldErrors["pincode"]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </PheonixCard>
            </Box>
            <Box sx={{ marginLeft: "2%", marginTop: "3%" }}>
              <PheonixCard
                isMobileView={isMobileView}
                title={TEXT_MESSAGES.BILLING_INFO}
                buttonDisabled={false}
                onClickButton={() => {}}
              >
                <IconButton
                  onClick={toggleVisibility2}
                  sx={{ marginLeft: "95%", marginTop: "-2%" }}
                >
                  {isVisible2 ? (
                    <RemoveIcon sx={{ color: "#69696E" }} />
                  ) : (
                    <AddIcon sx={{ color: "#69696E" }} />
                  )}
                </IconButton>
                {isVisible2 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: isMobileView ? "column" : "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {["GST_type", "GST_no"].map((key) => (
                        <div
                          key={key}
                          style={{
                            marginLeft: isMobileView ? "0px" : "3px",
                            flex: "1 1 200px",
                            marginBottom: "1rem",
                            minWidth: "200px",
                          }}
                        >
                          {isEditMode ? (
                            key === "GST_type" ? (
                              <Autocomplete
                                options={gstTypeOptions}
                                getOptionLabel={(option) => option.label}
                                value={
                                  gstTypeOptions.find(
                                    (option) =>
                                      option.value ===
                                      updateeditedData.billing_info[key]
                                  ) || null
                                }
                                onChange={(event, newValue) =>
                                  handleChange(
                                    key,
                                    newValue ? newValue.value : ""
                                  )
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={customLabels[key]}
                                    placeholder={`Select ${customLabels[key]}`}
                                  />
                                )}
                                sx={{ width: "250px" }}
                              />
                            ) : (
                              <PheonixTextField
                                id={key}
                                type="text"
                                value={updateeditedData.billing_info[key] || ""}
                                label={customLabels[key]}
                                onChange={(e) =>
                                  handleChange(key, e.target.value)
                                }
                                placeholder={`Enter ${customLabels[key]}`}
                                disabled={!isEditMode}
                                error={!!fieldErrors[key]}
                                helperText={fieldErrors[key]}
                              />
                            )
                          ) : (
                            <>
                              <p
                                style={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                              >
                                {customLabels[key]}
                              </p>
                              <div
                                style={{
                                  fontWeight: 400,
                                  color: "#000000",
                                  fontSize: "14px",
                                }}
                              >
                                {userData.billing_info[key] || "-"}
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: isMobileView ? "1rem" : "0",
                      }}
                    >
                      <div
                        key="currency"
                        style={{
                          marginLeft: isMobileView ? "0px" : "3px",
                          flex: "1 1 200px",
                          marginBottom: "1rem",
                          minWidth: "200px",
                        }}
                      >
                        {isEditMode ? (
                          <Autocomplete
                            options={currencyOptions}
                            getOptionLabel={(option) => option.label}
                            value={
                              currencyOptions.find(
                                (option) =>
                                  option.value ===
                                  updateeditedData.billing_info["currency"]
                              ) || null
                            }
                            onChange={(event, newValue) =>
                              handleChange(
                                "currency",
                                newValue ? newValue.value : ""
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={customLabels["currency"]}
                                placeholder={`Select ${customLabels["currency"]}`}
                              />
                            )}
                            sx={{ width: "250px" }}
                          />
                        ) : (
                          <>
                            <p
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              {customLabels["currency"]}
                            </p>
                            <div
                              style={{
                                fontWeight: 400,
                                color: "#000000",
                                fontSize: "14px",
                              }}
                            >
                              {userData.billing_info["currency"] || "-"}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </PheonixCard>
            </Box>

            <Divider
              style={{
                marginBottom: "1.5rem",
                marginTop: "0.5rem",
                marginRight: "20px",
              }}
            />

            <Button
              variant="contained"
              size="large"
              onClick={isEditMode ? handleSave : handleEdit}
              disabled={confirmDisable}
              sx={{
                fontWeight: "bold",
                height: "36px",
                right: "-85%",
                width: "125px",
                color: "#FFFFFF",
                marginBottom: "3%",
                backgroundColor: "#181D8C",
                "&:hover": {
                  backgroundColor: "#181D8C",
                },
              }}
            >
              {isEditMode ? "Save" : "Edit"}
            </Button>
            {isEditMode && (
              <Button
                variant="contained"
                size="large"
                onClick={handleCancel}
                sx={{
                  fontWeight: "bold",
                  height: "36px",
                  left: "-7%",
                  width: "125px",
                  marginTop: "-3%",
                  color: "#181D8C",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #181D8C",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                }}
              >
                Cancel
              </Button>
            )}
          </Paper>
        </>
      )}
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
    </div>
  );
};

export default Profilepage;
