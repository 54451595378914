import React, { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../Components/PheonixDeleteModal";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import PheonixSnackBar from "../Components/PheonixSnackBar";
import axios from "axios";
import Invoicemock from "../interfaces/Invoice";
import PheonixPaper from "../Components/PheonixPaper";
import Popover from "@mui/material/Popover";
import PheonixButton from "../Components/PheonixButton";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../assets/logo.jpg";
import { Typography, Box, Button, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useSignUpContext } from "../Contexts/SignUpContext";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import PheonixChip from "../Components/PheonixChip";
import PheonixModal from "../Components/PheonixModal";
import { TEXT_MESSAGES } from "../const";
import more from "../assets/Vector.png";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Fade from "@mui/material/Fade";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";
import { pdfdata } from "../const";
import { GET_INVOICE_QUERY, GET_INVOICE_WITH_TYPE } from "../graphql/query";
import { DELETE_INVOICE } from "../graphql/mutation";
const Invoicescreen: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [invoicessData, setInvoicesData] = useState<Invoicemock[]>([]);
  const [filteredInvoicesData, setFilteredInvoicesData] = useState<
    Invoicemock[]
  >([]);
  const [error, setError] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);
  const [entityToDisplay, setEntityToDisplay] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handleDeleteClick = (id: string, name: string) => {
    setEntityToDelete(id);
    setEntityToDisplay(name);
    setDeleteModalOpen(true);
  };
  const confirmDelete = async () => {
    if (!entityToDelete) return;
    const token = localStorage.getItem("token");
    const query = DELETE_INVOICE;
    const variables = {
      id: entityToDelete,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query,
          variables,
        },
        config
      );
      const { success, message } = response.data.data.deleteInvoice;
      if (success) {
        setSuccMessage(TEXT_MESSAGES.INVOICE_DELETE_SUCCESS);
        setDeleteModalOpen(false);
      } else {
        setSuccMessage(TEXT_MESSAGES.INVOICE_DELETE_FAIL);
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        fetchInvoiceData();
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInvoiceData();
  }, [
    currentPage,
    paginationModel.page,
    paginationModel.pageSize,
    searchQuery,
  ]);

  const fetchInvoiceData = async () => {
    const userId = localStorage.getItem("userId");
    const usertype = localStorage.getItem("usertype");
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (usertype === "1") {
      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query: GET_INVOICE_QUERY,
            variables: {
              userId: userId,
              page: paginationModel.page,
              perPage: paginationModel.pageSize,
              searchQuery: searchQuery,
            },
          },
          config
        );
        const responseDataWithIndex =
          response.data.data.getInvoicebyUser.invoices.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );
        setInvoicesData(responseDataWithIndex);
        setTotalPages(response.data.data.getInvoicebyUser.totalCount);
      } catch (error: any) {
        setError(error.message);
      }
    } else {
      try {
        const response = await axios.post(
          api.baseUrl,
          {
            query: GET_INVOICE_WITH_TYPE,
            variables: {
              customer_id: userId,
              page: paginationModel.page,
              perPage: paginationModel.pageSize,
              searchQuery: searchQuery,
            },
          },
          config
        );
        const responseDataWithIndex =
          response.data.data.getInvoicebyUserType.invoices.map(
            (item: {}, index: number) => ({
              ...item,
              serial_no: (index + 1).toString(),
            })
          );
        setInvoicesData(responseDataWithIndex);
        setTotalPages(response.data.data.getInvoicebyUserType.totalCount);
      } catch (error: any) {
        setError(error.message);
      }
    }
  };
  const getStatusColorAndIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return { color: "#05CD31", Icon: CheckCircleIcon };
      case "unpaid":
        return { color: "#9F9F9F", Icon: CancelIcon };
      case "partially paid":
        return { color: "#FC891F", Icon: CheckCircleIcon };
      case "cancelled":
        return { color: "#FD3232", Icon: CancelIcon };
      case "overdue":
        return { color: "#CD0505", Icon: ErrorOutlineOutlinedIcon };
      default:
        return { color: "#000000", Icon: CheckCircleIcon };
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleOpen = () => {
    navigate("/invoicenew");
  };

  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const imageUrl = logo;
  const handleDownloadPDF = (rowData: Invoicemock) => {
    const doc = new jsPDF() as any;
    doc.addImage(imageUrl, "JPEG", 15, 10, 50, 35);
    doc.setFontSize(40);
    doc.setFont("helvetica", "bold");
    doc.text("Invoice", 145, 20);
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginRight = 15;
    const labelMarginLeft = 14;

    const rightAlignTextWithColors = (
      label: any,
      value: any,
      labelColor: any,
      valueColor: any,
      yPosition: any,
      applyLabelMarginLeft: any
    ) => {
      const labelWidth =
        (doc.getStringUnitWidth(label) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const valueWidth =
        (doc.getStringUnitWidth(value) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const totalWidth = labelWidth + valueWidth;

      const xLabel =
        pageWidth -
        totalWidth -
        marginRight -
        (applyLabelMarginLeft ? labelMarginLeft : 0);
      const xValue =
        xLabel + labelWidth + (applyLabelMarginLeft ? labelMarginLeft : 0);

      doc.setTextColor(labelColor);
      doc.text(label, xLabel, yPosition);

      doc.setTextColor(valueColor);
      doc.text(value, xValue, yPosition);
    };

    const texts = [
      {
        label: "Invoice ID : ",
        value: rowData.invoiceid,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: true,
      },
      {
        label: "Invoice Date : ",
        value: rowData.invoicedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
      {
        label: "Valid Till : ",
        value: rowData.duedate,
        labelColor: "#808080",
        valueColor: "#000000",
        applyLabelMarginLeft: false,
      },
    ];

    const yPositions = [28, 35, 42];
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    texts.forEach((text, index) => {
      rightAlignTextWithColors(
        text.label,
        text.value,
        text.labelColor,
        text.valueColor,
        yPositions[index],
        text.applyLabelMarginLeft
      );
    });

    doc.setTextColor("#808080");
    doc.text("From ", 15, 60);
    doc.setTextColor("#000000");
    const pheonixAddress = pdfdata.PHEONIX_ADDRESS;
    doc.text(pheonixAddress, 12, 65);

    const text1 = "To ";

    const text2 = rowData.customer_name;
    const text3 = rowData.phone_no;
    const textWidth1 =
      (doc.getStringUnitWidth(text1) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textWidth2 =
      (doc.getStringUnitWidth(text2) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textWidth3 =
      (doc.getStringUnitWidth(text3) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;

    const x1 = pageWidth - textWidth1 - marginRight;
    const x2 = pageWidth - textWidth2 - marginRight;
    const x3 = pageWidth - textWidth3 - marginRight;
    doc.setTextColor("#808080");
    doc.text(text1, x1, 60);
    doc.setTextColor("#000000");
    doc.text(text2, x2, 70);
    doc.text(text3, x3, 75);
    const products = rowData.productOrService.map((service: any) => ({
      name: service.name,
      amount: service.amount,
    }));
    const bodyData = products.map((name) => [
      name.name,
      rowData.description,
      name.amount,
    ]);
    let startY = 110;
    var customStyles = {
      fillColor: [255, 255, 255],
      lineHeight: 50,
      cellPadding: 2,
      textColor: [0, 0, 0],
      fontSize: 12,
    };
    doc.autoTable({
      startY,
      head: [["Product/Service", "Description", "Price"]],
      body: bodyData,
      styles: customStyles,
      didParseCell: function (table: any) {
        if (table.section === "head") {
          table.cell.styles.textColor = "#000000";
          table.cell.styles.fontSize = 12;
        }
      },
    });
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    const text = `Total : ${rowData.price}`;
    doc.text(text, 170, 160);

    doc.save(`IN-${rowData.invoiceid}.pdf`);
  };

  const [userType, setUserType] = useState<number | null>(null);
  useEffect(() => {
    const storedUserType = localStorage.getItem("usertype");
    if (storedUserType !== null) {
      const parsedUserType = parseInt(storedUserType, 10);
      setUserType(parsedUserType);
    }
  }, []);

  const handleViewClick = (row: Invoicemock) => {
    navigate("/invoicenew", { state: { row } });
  };
  const columns = [
    { field: "serial_no", headerName: "S.No", width: 150 },
    { field: "invoicedate", headerName: "Date", width: 150 },
    { field: "invoice_no", headerName: "Invoice Number", width: 175 },
    { field: "customer_name", headerName: "Customer Name", width: 175 },
    {
      field: "status",
      headerName: "Status",
      width: 175,
      renderCell: (params: { value: string }) => {
        const status = params.value;
        const { color, Icon } = getStatusColorAndIcon(status);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Icon style={{ color, marginRight: "5px" }} />
            {status}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Amount",
      width: 175,
      renderCell: (params: any) => {
        return params.value != null ? `₹ ${params.value}` : "NA";
      },
    },
    {
      field: "due_amount",
      headerName: "Amount Due",
      width: 150,
      renderCell: (params: any) => {
        return params.value !== null && !isNaN(params.value)
          ? `₹ ${params.value}`
          : "NA";
      },
    },
    ...(userType === 2
      ? [
          {
            field: "action",
            headerName: "Action",
            renderCell: (params: { row: Invoicemock }) => (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: "50px",
                  cursor: "pointer",
                }}
              >
                <SaveAltIcon
                  style={{ color: "#165D59" }}
                  onClick={() => handleDownloadPDF(params.row)}
                />
              </div>
            ),
            width: 100,
          },
        ]
      : [
          {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params: any) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  marginLeft: "-18px",
                }}
              >
                <IconButton onClick={() => handleViewClick(params.row)}>
                  <VisibilityIcon
                    style={{
                      width: "24px",
                      height: "26px",
                      color: "181D8C",
                      marginTop: "7px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => handleDownloadPDF(params.row)}>
                  <SimCardDownloadOutlinedIcon
                    style={{
                      width: "24px",
                      height: "26px",
                      color: "181D8C",
                      marginTop: "7px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    handleDeleteClick(params.row.id, params.row.invoice_no)
                  }
                >
                  <DeleteIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "181D8C",
                      marginTop: "7px",
                      cursor: "pointer",
                    }}
                  />
                </IconButton>
              </div>
            ),
            align: "center",
          },
        ]),
  ];

  return (
    <div
      style={{
        backgroundColor: "white",
        overflow: "hidden",
        minHeight: "84vh",
        padding: "20px",
        maxWidth: isSidebarOpen ? "calc(100% - 70px)" : "calc(100% - 80px)",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
              textAlign: "center",
              lineHeight: "21px",
            }}
          >
            {TEXT_MESSAGES.INVOICE}
          </Typography>
          <PheonixBreadcrumbs />
        </div>
        <div style={{ marginRight: "-10px" }}>
          {loggedInUser === "1" && (
            <PheonixButton
              style={{
                marginRight: "10px",
                color: "white",
                border: "1px solid #1C1B1F",
                backgroundColor: "#181D8C",
              }}
              onClick={handleOpen}
              label={TEXT_MESSAGES.NEW_INVOICE}
            ></PheonixButton>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "20px",
          border: "0.5px solid #B7BAFF",
        }}
      >
        <PheonixDataGrid
          rows={
            filteredInvoicesData.length > 0
              ? filteredInvoicesData
              : invoicessData
          }
          columns={columns}
          pageSizeOptions={[5, 10, 20, 30]}
          rowCount={totalPages}
          style={{ border: "0px", marginLeft: "20px" }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationChange}
          onSearchQueryChange={handleSearchQueryChange}
        />
      </div>
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={succMessage}
        vertical="top"
        horizontal="center"
      />
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
        entity={entityToDisplay}
        entityname="Invoice"
      />
    </div>
  );
};
export default Invoicescreen;
