import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  TextField,
  IconButton,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import Flatpickr from "react-flatpickr";
import { ERROR_MESSAGES, TEXT_MESSAGES } from "../const";
import { api } from "../api";
import axios from "axios";
import { GET_ALL_PRODUCTS } from "../graphql/query";
import PheonixTextField from "../Components/PheonixTextField";
import PheonixProductTable from "./PheonixProductTable";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { RowData, TableColumn } from "../interfaces/PheonixProductTableprops";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";
import QuoteTableProps from "../interfaces/QuoteTableProps";
import QuoteData from "../interfaces/PheonixQuotation";
import { ProductData } from "../interfaces/PheonixProductTableprops";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const QuoteReqtable: React.FC<QuoteTableProps> = ({
  QuotesData,
  view,
  edit,
  setQuotesData,
  fieldErrors,
  setFieldErrors,
  rows,
  setRows,
  tableFieldErrors,
  setTableFieldErrors,
}) => {
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [allproductsData, setAllProductsData] = useState<ProductData[]>([]);
  const [error, setError] = useState("");


  useEffect(() => {
    fetchProducts();
  }, []);

  
  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_PRODUCTS,
        },
        config
      );
      const products = response.data.data.getallproducts;
      const formattedProducts = products.map((product: any) => ({
        "Product Plan": product.productplan || "",
      }));
      setAllProductsData(formattedProducts);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const usertype = localStorage.getItem("usertype");
    if (usertype !== null) {
      setLoggedInUser(usertype);
    }
  }, []);

  const handleQuotationDateChange = (date: any) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setQuotesData((prevState) => ({
      ...prevState,
      ["Quotation Date"]: formattedDate,
    }));
  };

  const handleInputChange = (label: string, value: string) => {
    const newFormData: QuoteData = { ...QuotesData, [label]: value };
    switch (label) {
      case "Customer Notes":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.DESC_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;

      case "Product/Plan":
        if (!value) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [label]: ERROR_MESSAGES.PROD_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [label]: "" }));
        }
        break;

      default:
        return true;
    }

    setQuotesData(newFormData);
  };

  const handleDeleteRow = (index: number) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
      setTableFieldErrors((prevErrors: { [key: string]: any }) => {
        const newErrors = { ...prevErrors };
        if (newErrors[index]) {
          Object.keys(newErrors[index]).forEach((field) => {
            newErrors[index][field] = "";
          });
        }
        return newErrors;
      });
    }
  };
  const handleAddRow = () => {
    const newRow = {
      "Product Details": "",
      Quantity: 0,
      "Product Plan": "",
    };
    setRows([...rows, newRow]);
  };

  const handleInputChange1 = (
    index: number,
    fieldName: keyof (typeof rows)[0],
    value: any
  ) => {
    const newRows = [...rows];

    let hasError = false;

    if (fieldName === "Product Details") {
      const selectedProduct = allproductsData.find(
        (product) => product["Product Name"] === value
      );

      if (selectedProduct) {
        newRows[index] = {
          ...newRows[index],
          "Product Details": value,
        };
      } else {
        newRows[index] = {
          ...newRows[index],
          "Product Details": value,
        };
      }
    } else {
      newRows[index] = {
        ...newRows[index],
        [fieldName]: value,
      };

      if (fieldName === "Quantity") {
        const rate = Number(newRows[index].Rate) || 0;
        const quantity = Number(newRows[index].Quantity) || 0;
        const amount = (rate * quantity).toFixed(2);
        newRows[index].Amount = amount;
      }
    }

    if (fieldName === "Product Details" && /[^\w\s]/.test(value)) {
      hasError = true;
      setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          [fieldName]: ERROR_MESSAGES.BATCH_VAL,
        },
      }));
    } else {
      setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
        ...prevErrors,
        [index]: {
          ...prevErrors[index],
          [fieldName]: "",
        },
      }));
    }

    if (fieldName === "Quantity") {
      if (!value) {
        newRows[index].Quantity = 0;
        newRows[index].Amount = (Number(newRows[index].Rate) * 0).toFixed(2);
      } else if (!/^\d+$/.test(value)) {
        hasError = true;
        setTableFieldErrors((prevErrors: { [key: string]: any }) => ({
          ...prevErrors,
          [index]: {
            ...prevErrors[index],
            [fieldName]: ERROR_MESSAGES.QUANTITY_VAL,
          },
        }));
      } else {
        newRows[index].Quantity = Number(value);
      }
    }

    if (!hasError) {
      setRows(newRows);
    }
  };

  const getColumns = () => {
    const baseColumns = [
      {
        field: "Product Details",
        headerName: "Product Details",
      },
      {
        field: "Product Plan",
        headerName: "Product Plan",
      },

      {
        field: "Quantity",
        headerName: "Quantity",
      },

      {
        field: "Action",
        headerName: "Action",
        render: (index: number) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              disabled={view && !edit}
              onClick={() => handleAddRow()}
              style={{ color: "#181D8C" }}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              disabled={view && !edit}
              onClick={() => handleDeleteRow(index)}
              style={{ color: "#D32F2F" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        ),
      },
    ];

    return baseColumns;
  };

  const columns = getColumns();

  return (
    <Box>
      <table style={{ width: "100%", marginTop: "1%" }}>
        <tbody>
          <tr>
            <td
              style={{
                display: "flex",
                padding: "10px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={
                    <>
                      <span style={{ color: "#181D8C" }}>Quotation Date</span>
                      <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                    </>
                  }
                  format="DD/MM/YYYY"
                  disabled={view && !edit}
                  onChange={handleQuotationDateChange}
                  disablePast
                  value={
                    QuotesData["Quotation Date"]
                      ? dayjs(QuotesData["Quotation Date"], "DD/MM/YYYY")
                      : null
                  }
                  sx={{
                    width: 309,
                  }}
                />
              </LocalizationProvider>
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>

          <tr>
            <td colSpan={5}>
              <div>
                <Card>
                  <div>
                    <PheonixProductTable
                      rows={rows}
                      columns={columns}
                      handleInputChange={handleInputChange1}
                      handleAddRow={handleAddRow}
                      handleDeleteRow={handleDeleteRow}
                      fieldErrors={tableFieldErrors}
                      view={view}
                      edit={edit}
                      productsData={allproductsData}
                      setProductsData={setAllProductsData}
                    />
                  </div>
                </Card>
              </div>
            </td>
          </tr>

          <tr>
            <td style={{ padding: "10px" }}>
              <PheonixTextField
                id="outlined-error"
                type="textarea"
                disabled={view && !edit}
                value={QuotesData["Customer Notes"]}
                placeholder="Customer Notes"
                name="Customer Notes"
                label={
                  <>
                    <span style={{ color: "#181D8C" }}>Customer Notes</span>
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  </>
                }
                variant="outlined"
                size="small"
                error={!!fieldErrors?.["Customer Notes"]}
                helperText={fieldErrors["Customer Notes"]}
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
                style={{ width: "652px" }}
                multiline={true}
                rows={2}
              />
            </td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
            <td style={{ width: "25%" }}></td>
          </tr>
        </tbody>
      </table>
    </Box>
  );
};
export default QuoteReqtable;
